import React, { useState, useMemo } from "react";
import { db } from "./firebase";
import {
  collection,
  query,
  getDocs,
  where,
  Timestamp,
  orderBy,
  limit,
  doc,
  getDoc,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faSearch,
  faArrowDownAZ,
  faArrowDownZA,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { useQuery } from "@tanstack/react-query";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Purgatory = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("firstName");
  const [sortDirection, setSortDirection] = useState("asc");

  const columnMap = {
    "First Name": "firstName",
    "Last Name": "lastName",
    "Work Location": "location",
    "Reports To": "reportsTo",
    "Latest Shift Date": "latestShiftDate",
    "Hiring Date": "hiringDate",
  };

  const fetchData = async () => {
    // Fetch active employees
    const usersRef = collection(db, "users");
    const usersQuery = query(
      usersRef,
      where("status", "==", "Active"),
      where("role", "==", "employee")
    );
    const usersSnapshot = await getDocs(usersQuery);
    const activeEmployees = usersSnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter((user) => user.id !== "QdIwzt9dyEakcYHDsr7JQmvniZ43");

    // Fetch shifts from the last 30 days
    const shiftsRef = collection(db, "shifts");
    const thirtyDaysAgo = Timestamp.fromDate(
      new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    );
    const shiftsQuery = query(
      shiftsRef,
      where("startTimestamp", ">=", thirtyDaysAgo)
    );
    const shiftsSnapshot = await getDocs(shiftsQuery);
    const recentShifts = shiftsSnapshot.docs.map((doc) => doc.data());

    // Create a map of userId to their latest shift date
    const latestShiftMap = new Map();
    recentShifts.forEach((shift) => {
      const userId = shift.userId;
      const shiftDate = shift.startTimestamp.toDate();
      if (
        !latestShiftMap.has(userId) ||
        shiftDate > latestShiftMap.get(userId)
      ) {
        latestShiftMap.set(userId, shiftDate);
      }
    });

    // Identify users without recent shifts and get their latest shift date
    const usersWithoutRecentShifts = activeEmployees.filter(
      (user) => !latestShiftMap.has(user.id)
    );

    // Fetch the latest shift for users without recent shifts
    const usersWithoutRecentShiftsData = await Promise.all(
      usersWithoutRecentShifts.map(async (user) => {
        const latestShiftQuery = query(
          shiftsRef,
          where("userId", "==", user.id),
          orderBy("startTimestamp", "desc"),
          limit(1)
        );
        const latestShiftSnapshot = await getDocs(latestShiftQuery);
        const latestShift = latestShiftSnapshot.docs[0]?.data();

        // Fetch reportsTo user details
        let reportsToNames = [];
        if (Array.isArray(user.reportsTo) && user.reportsTo.length > 0) {
          for (const reportsToId of user.reportsTo) {
            try {
              const reportsToDocRef = doc(db, "users", reportsToId.trim());
              const reportsToDocSnap = await getDoc(reportsToDocRef);

              if (reportsToDocSnap.exists()) {
                const reportsToData = reportsToDocSnap.data();
                const firstName = reportsToData.firstName || "";
                const lastName = reportsToData.lastName || "";
                const fullName = `${firstName} ${lastName}`.trim();
                if (fullName) reportsToNames.push(fullName);
              } else {
              }
            } catch (error) {
              console.error("Error fetching reportsTo user:", error);
            }
          }
        } else {
        }

        return {
          id: user.id,
          firstName: user.firstName || "Unknown",
          lastName: user.lastName || "Unknown",
          location: user.workLocation || "Unknown",
          reportsTo:
            reportsToNames.length > 0 ? reportsToNames.join(", ") : "Unknown",
          latestShiftDate: latestShift
            ? latestShift.startTimestamp.toDate()
            : null,
          hiringDate: user.hiringDate || "Unknown",
        };
      })
    );

    return usersWithoutRecentShiftsData;
  };

  const {
    data: employeesWithoutShifts,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["employeesWithoutShifts"],
    queryFn: fetchData,
  });

  const filteredEmployees = useMemo(() => {
    if (!employeesWithoutShifts) return [];
    return employeesWithoutShifts.filter((employee) =>
      `${employee.firstName} ${employee.lastName} ${employee.location} ${employee.reportsTo}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }, [employeesWithoutShifts, searchTerm]);

  const sortedEmployees = useMemo(() => {
    if (!filteredEmployees) return [];
    return [...filteredEmployees].sort((a, b) => {
      let aValue = a[sortColumn];
      let bValue = b[sortColumn];

      if (sortColumn === "latestShiftDate") {
        aValue = a.latestShiftDate ? a.latestShiftDate.getTime() : 0;
        bValue = b.latestShiftDate ? b.latestShiftDate.getTime() : 0;
      } else if (typeof aValue === "string" && typeof bValue === "string") {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
      if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredEmployees, sortColumn, sortDirection]);

  const handleSort = (column) => {
    const mappedColumn = columnMap[column] || column;

    if (mappedColumn === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(mappedColumn);
      setSortDirection("asc");
    }
  };

  const getSortIcon = (column) => {
    const mappedColumn = columnMap[column] || column;

    if (sortColumn === mappedColumn && sortDirection === "desc") {
      return faArrowDownZA;
    }
    return faArrowDownAZ;
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsHeader = [
      [
        "First Name",
        "Last Name",
        "Work Location",
        "Reports To",
        "Latest Shift Date",
        "Hiring Date",
      ],
    ];

    const wsData = filteredEmployees.map((employee) => [
      employee.firstName,
      employee.lastName,
      employee.location,
      employee.reportsTo,
      employee.latestShiftDate
        ? employee.latestShiftDate.toLocaleDateString()
        : "No shifts found",
      employee.hiringDate && employee.hiringDate !== "Unknown"
        ? new Date(employee.hiringDate).toLocaleDateString()
        : employee.hiringDate,
    ]);

    const ws = XLSX.utils.aoa_to_sheet(wsHeader.concat(wsData));
    XLSX.utils.book_append_sheet(wb, ws, "Employees Without Recent Shifts");

    const filename = `employees_without_recent_shifts_${
      new Date().toISOString().split("T")[0]
    }.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  const getResultText = (count, isLoading) => {
    if (isLoading) return "Loading results...";
    if (count === 0) return "No results found";
    return `${count} result${count !== 1 ? "s" : ""} found`;
  };

  if (error) {
    return (
      <div className="bg-[#1F2937] min-h-screen text-white p-8">
        Error: {error.message}
      </div>
    );
  }

  return (
    <div className="bg-[#1F2937] min-h-screen text-white p-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Employees Without Recent Shifts</h1>
        <button
          onClick={exportToExcel}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          <FontAwesomeIcon icon={faFileExport} className="mr-2" /> Export
        </button>
      </div>

      <div className="mb-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search by name or location..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 text-gray-700 bg-white border rounded-full focus:border-blue-500 focus:outline-none focus:ring"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute right-3 top-3 text-gray-400"
          />
        </div>
        <div className="mt-2 text-white font-semibold text-grey-600 uppercase tracking-wider">
          {getResultText(filteredEmployees.length, isLoading)}
        </div>
      </div>

      {isLoading ? (
        <table className="min-w-full bg-white rounded-lg">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              {Object.keys(columnMap).map((column) => (
                <th key={column} className="px-4 py-2">
                  <div className="flex items-center justify-between">
                    <span>{column}</span>
                    <FontAwesomeIcon
                      icon={faArrowDownAZ}
                      className="ml-1 opacity-50"
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {[...Array(5)].map((_, index) => (
              <tr key={index}>
                {[...Array(6)].map((_, cellIndex) => (
                  <td key={cellIndex} className="border px-4 py-2">
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : filteredEmployees.length === 0 ? (
        <p>No employees found matching your search criteria.</p>
      ) : (
        <table className="min-w-full bg-white rounded-lg">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              {Object.keys(columnMap).map((column) => (
                <th
                  key={column}
                  className="px-4 py-2 cursor-pointer"
                  onClick={() => handleSort(column)}
                >
                  <div className="flex items-center justify-between">
                    <span>{column}</span>
                    <FontAwesomeIcon
                      icon={getSortIcon(column)}
                      className={`ml-1 ${
                        sortColumn === columnMap[column]
                          ? "opacity-100"
                          : "opacity-50"
                      }`}
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {sortedEmployees.map((employee) => (
              <tr key={employee.id}>
                <td className="border px-4 py-2">{employee.firstName}</td>
                <td className="border px-4 py-2">{employee.lastName}</td>
                <td className="border px-4 py-2">{employee.location}</td>
                <td className="border px-4 py-2">{employee.reportsTo}</td>
                <td className="border px-4 py-2">
                  {employee.latestShiftDate
                    ? employee.latestShiftDate.toLocaleDateString()
                    : "No shifts found"}
                </td>
                <td className="border px-4 py-2">
                  {employee.hiringDate && employee.hiringDate !== "Unknown"
                    ? new Date(employee.hiringDate).toLocaleDateString()
                    : employee.hiringDate}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Purgatory;
