import React, { useState, useMemo, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "./firebase";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faEnvelope,
  faCheck, 
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// Fetching function to get applications
const fetchApplications = async () => {
  const applicationsRef = collection(db, "applications");
  const applicationsSnapshot = await getDocs(applicationsRef);
  let states = new Set();
  let applicationsData = applicationsSnapshot.docs.map((doc) => {
    const data = doc.data();
    states.add(data.state);
    return { ...data, id: doc.id };
  });

  return { applicationsData, states: Array.from(states).sort() };
};

function ApplicationReview() {
  const [selectedState, setSelectedState] = useState("");
  const [filterType, setFilterType] = useState("all");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, // You can adjust this number
  });

  // Use useQuery to fetch applications and states
  const { data, isLoading, error } = useQuery({
    queryKey: ["applications"],
    queryFn: fetchApplications,
  });

  const handleStateChange = useCallback((e) => {
    setSelectedState(e.target.value);
  }, []);

  const filteredApplications = useMemo(() => {
    if (!data) return [];
    return data.applicationsData.filter((application) => {
      // First filter by state if selected
      const stateMatch = selectedState === "" || application.state === selectedState;
      
      // Then filter by type
      switch (filterType) {
        case "pending":
          return stateMatch && !application.isInterviewPassed;
        case "passed":
          return stateMatch && application.isInterviewPassed;
        case "all":
        default:
          return stateMatch;
      }
    });
  }, [data, selectedState, filterType]);

  const sortedApplications = useMemo(() => {
    return filteredApplications.sort((a, b) => {
      const dateA = a.timestamp.toDate();
      const dateB = b.timestamp.toDate();
      return dateB - dateA;
    });
  }, [filteredApplications]);

  // Define columns for the table
  const columns = useMemo(() => [
    {
      header: "Name",
      accessorFn: (row) => `${row.firstName} ${row.middleName} ${row.lastName}`,
      cell: ({ row }) => {
        const phone = row.original.phone || '';
        const isValidPhoneNumber = phone && phone.length >= 10;
        const email = row.original.email;

        return (
          <td className="px-2 py-3 border-b border-gray-300 text-sm">
            <div className="font-medium text-base">
              {row.original.firstName} {row.original.middleName} {row.original.lastName}
            </div>
            <div className="mt-2 flex flex-row gap-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (isValidPhoneNumber) {
                    window.open(`https://wa.me/${phone}`, "_blank");
                  }
                }}
                className={`inline-flex items-center justify-center w-24 px-2 py-1 text-xs leading-4 font-medium rounded-md ${
                  isValidPhoneNumber 
                    ? "text-white bg-[#25D366] hover:bg-[#20BD5C]" 
                    : "text-gray-400 bg-gray-100"
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors`}
                disabled={!isValidPhoneNumber}
              >
                <FontAwesomeIcon icon={faWhatsapp} className="h-3 w-3 mr-1" />
                WhatsApp
              </button>
              
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  window.location.href = `mailto:${email}`;
                }}
                className="inline-flex items-center justify-center w-24 px-2 py-1 text-xs leading-4 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
              >
                <FontAwesomeIcon icon={faEnvelope} className="h-3 w-3 mr-1" />
                Email
              </button>
            </div>
          </td>
        );
      },
    },
    {
      header: "Submitted On",
      accessorFn: (row) => row.timestamp,
      cell: ({ row }) => {
        const formattedDate = row.original.timestamp.toDate().toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZoneName: "short",
        });
        return formattedDate;
      },
    },
    {
      header: "City/State",
      accessorFn: (row) => `${row.city}, ${row.state}`,
      cell: ({ row }) => (
        <div className="font-small text-lg">
          {row.original.city}, {row.original.state}
        </div>
      ),
    },
    {
      header: "Actions",
      cell: ({ row }) => (
        <Link
          to={`/interview/${row.original.id}`}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
        >
          <svg className="h-4 w-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
          </svg>
          View Details
        </Link>
      ),
    },
    {
      header: "Interview Status",
      accessorFn: (row) => row.isInterviewPassed || false,
      cell: ({ row }) => {
        const isPassed = row.original.isInterviewPassed || false;
        return (
          <div className="flex items-center space-x-2">
            {isPassed ? (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  // Update to pending
                  const applicationRef = doc(db, "applications", row.original.id);
                  updateDoc(applicationRef, {
                    isInterviewPassed: false
                  });
                }}
                className="bg-green-100 text-green-800 px-4 py-2 rounded-full flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faCheck} className="h-6 w-6" />
                <span className="ml-2 font-bold">Passed Interview</span>
              </button>
            ) : (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  // Update to passed
                  const applicationRef = doc(db, "applications", row.original.id);
                  updateDoc(applicationRef, {
                    isInterviewPassed: true
                  });
                }}
                className="bg-yellow-100 text-yellow-800 px-4 py-2 rounded-full flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
                <span className="ml-2 font-bold">Interview Pending</span>
              </button>
            )}
          </div>
        );
      },
    },
    // ... add other columns as needed
  ], []);

  const table = useReactTable({
    data: sortedApplications || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
  });

  if (isLoading) {
    return (
      <div className="bg-[#1F2937] p-4 overflow-x-hidden max-w-full">
        <div className="mb-8">
          <Skeleton height={32} width={200} className="mb-2" />
          <Skeleton height={20} width={300} />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="bg-white/10 backdrop-blur-sm rounded-lg p-6 shadow-lg border border-white/20">
              <Skeleton height={20} width={120} />
              <Skeleton height={40} width={80} className="mt-2" />
            </div>
          ))}
        </div>

        <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 mb-8 shadow-lg border border-white/20">
          <Skeleton height={40} />
        </div>

        <div className="overflow-x-auto">
          <div className="bg-white/10 backdrop-blur-sm rounded-lg shadow-lg border border-white/20 min-w-full">
            <table className="w-full table-auto">
              <thead>
                <tr>
                  {[...Array(5)].map((_, i) => (
                    <th key={i} className="px-3 py-4 border-b border-white/20">
                      <Skeleton height={20} />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {[...Array(5)].map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    {[...Array(5)].map((_, colIndex) => (
                      <td key={colIndex} className="px-3 py-4 border-b border-gray-700">
                        <Skeleton height={colIndex === 0 ? 60 : 30} />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="bg-[#1F2937] p-4 overflow-x-hidden max-w-full">
      <div className="mb-8">
        <h1 className="text-lg font-bold text-white uppercase tracking-wider mb-2">
          Application Review
        </h1>
        <p className="text-gray-400">Manage and review candidate applications</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <button
          onClick={() => setFilterType("all")}
          className={`bg-white/10 backdrop-blur-sm rounded-lg p-6 shadow-lg border transition-all ${
            filterType === "all" 
              ? "border-blue-500 ring-2 ring-blue-500/50" 
              : "border-white/20 hover:border-blue-500"
          }`}
        >
          <div className="text-sm font-medium text-gray-200">Total Applications</div>
          <div className="text-4xl font-bold text-white mt-2">
            {data?.applicationsData.length}
          </div>
        </button>
        
        <button
          onClick={() => setFilterType("pending")}
          className={`bg-white/10 backdrop-blur-sm rounded-lg p-6 shadow-lg border transition-all ${
            filterType === "pending" 
              ? "border-yellow-500 ring-2 ring-yellow-500/50" 
              : "border-white/20 hover:border-yellow-500"
          }`}
        >
          <div className="text-sm font-medium text-gray-200">Pending Interviews</div>
          <div className="text-4xl font-bold text-yellow-300 mt-2">
            {data?.applicationsData.filter(app => !app.isInterviewPassed).length}
          </div>
        </button>
        
        <button
          onClick={() => setFilterType("passed")}
          className={`bg-white/10 backdrop-blur-sm rounded-lg p-6 shadow-lg border transition-all ${
            filterType === "passed" 
              ? "border-green-500 ring-2 ring-green-500/50" 
              : "border-white/20 hover:border-green-500"
          }`}
        >
          <div className="text-sm font-medium text-gray-200">Passed Interviews</div>
          <div className="text-4xl font-bold text-green-300 mt-2">
            {data?.applicationsData.filter(app => app.isInterviewPassed).length}
          </div>
        </button>
        
        <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 shadow-lg border border-white/20 hover:border-blue-500 transition-all">
          <div className="text-sm font-medium text-gray-200">States Covered</div>
          <div className="text-4xl font-bold text-blue-300 mt-2">{data?.states.length}</div>
        </div>
      </div>

      <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 mb-8 shadow-lg border border-white/20">
        <div className="flex items-center space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-200 mb-2">Filter by State</label>
            <select
              onChange={handleStateChange}
              value={selectedState}
              className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all"
            >
              <option value="">All States</option>
              {data.states.map((state, index) => (
                <option key={index} value={state}>{state}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <div className="bg-white/10 backdrop-blur-sm rounded-lg shadow-lg border border-white/20 min-w-full">
          <table className="w-full table-auto">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th 
                      key={header.id} 
                      className="px-3 py-4 border-b border-white/20 bg-white/5 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={`transition-all hover:bg-gray-700 ${
                    row.original.isInterviewPassed
                      ? "bg-green-900/30 border-l-4 border-l-green-500"
                      : "border-l-4 border-l-transparent"
                  }`}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td 
                      key={cell.id} 
                      className="px-3 py-4 text-sm text-gray-300 border-b border-gray-700"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="px-3 py-4 bg-gray-800 border-t border-gray-700 flex flex-col sm:flex-row items-center justify-between mt-4 rounded-lg">
        <div className="inline-flex rounded-full shadow-sm mb-2 sm:mb-0" role="group">
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className={`inline-flex items-center px-4 py-2 text-sm font-medium text-blue-300 bg-gray-900 rounded-l-full hover:bg-gray-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:outline-none transition-colors duration-200 ${
              !table.getCanPreviousPage() ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <FontAwesomeIcon icon={faLongArrowAltLeft} className="mr-2" />
            Previous
          </button>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className={`inline-flex items-center px-4 py-2 text-sm font-medium text-blue-300 bg-gray-900 rounded-r-full hover:bg-gray-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:outline-none transition-colors duration-200 ${
              !table.getCanNextPage() ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Next
            <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-2" />
          </button>
        </div>
        <span className="text-sm text-gray-400">
          Page{" "}
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>{" "}
          | Total {sortedApplications.length} applications
        </span>
      </div>
    </div>
  );
}

export default ApplicationReview;
