import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  deleteDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import { useUser } from "./UserContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX, faTrash } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function EmployeeInterview() {
  const { id } = useParams();
  const [notes, setNotes] = useState("");
  const [saveStatus, setSaveStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { userName } = useUser();

  // Create state variables to store the application details
  const [applicationDetails, setApplicationDetails] = useState({});

  const [isEditing, setIsEditing] = useState({
    firstName: false,
    middleName: false,
    lastName: false,
    email: false,
    phone: false,
    birthDate: null,
    address: false,
    city: false,
    state: false,
    zip: false,
    jobType: false,
    availability: false,
    restrictions: false,
    identificationType: false,
    hasLicense: false,
    speaksEnglish: false,
    englishProficiencyLevel: false,
    notes: false,
    rate: false,
    isInterviewPassed: false,
  });

  const [editedValues, setEditedValues] = useState({
    firstName: applicationDetails.firstName || "",
    middleName: applicationDetails.middleName || "",
    lastName: applicationDetails.lastName || "",
    email: applicationDetails.email || "",
    phone: applicationDetails.phone || "",
    birthDate: applicationDetails.birthDate || "",
    address: applicationDetails.address || "",
    city: applicationDetails.city || "",
    state: applicationDetails.state || "",
    zip: applicationDetails.zip || "",
    jobType: applicationDetails.jobType || "",
    availability: applicationDetails.availability || "",
    restrictions: applicationDetails.restrictions || "",
    identificationType: applicationDetails.identificationType || "none",
    hasLicense: applicationDetails.hasLicense || false,
    speaksEnglish: applicationDetails.speaksEnglish || false,
    englishProficiencyLevel: applicationDetails.speaksEnglish
      ? applicationDetails.englishProficiencyLevel || "basic"
      : "",
    notes: applicationDetails.notes || "",
    rate: applicationDetails.rate || "",
    isInterviewPassed: applicationDetails.isInterviewPassed || false,
  });

  useEffect(() => {
    const fetchApplicationDetails = async () => {
      const applicationRef = doc(db, "applications", id);
      const docSnapshot = await getDoc(applicationRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setApplicationDetails(data);

        // Update editedValues with the fetched data
        setEditedValues((prev) => ({
          ...prev,
          ...data,
          identificationType: data.identificationType || "none",
          hasLicense: data.hasLicense || false,
          speaksEnglish: data.speaksEnglish || false,
          englishProficiencyLevel: data.speaksEnglish
            ? data.englishProficiencyLevel || "basic"
            : "",
          notes: data.notes || "",
          isInterviewPassed: data.isInterviewPassed || false,
        }));

        // Set notes with fetched data
        setNotes(data.notes || "");
      } else {
        console.error("No document found with the given ID:", id);
      }
    };

    fetchApplicationDetails();
  }, [id]);

  async function handleInterviewPassed() {
    setIsLoading(true);
    const applicationDocRef = doc(db, "applications", id);

    try {
      await updateDoc(applicationDocRef, {
        isInterviewPassed: true,
      });

      setEditedValues((prev) => ({
        ...prev,
        isInterviewPassed: true,
      }));

      setApplicationDetails((prev) => ({
        ...prev,
        isInterviewPassed: true,
      }));

      // Show a success message or handle UI changes
      console.log("Interview status updated successfully.");

      // Redirect to the last screen
      redirectToDashboard();
    } catch (error) {
      console.error("Error updating interview status:", error);
    } finally {
      setIsLoading(false);
    }
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  function getCurrentFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const autoSaveNotes = debounce(async (currentValue) => {
    setSaveStatus("Saving...");

    const applicationDocRef = doc(db, "applications", id);

    try {
      await updateDoc(applicationDocRef, {
        notes: currentValue,
      });

      setSaveStatus("Saved");
    } catch (error) {
      console.error("Error updating document: ", error);
      setSaveStatus("Error saving notes. Please try again.");
    }
  }, 500);

  function handleNotesChange(e) {
    const currentValue = e.target.value;
    setNotes(currentValue);
    autoSaveNotes(currentValue);
  }

  function calculateAge(birthDateString) {
    const birthDate = new Date(birthDateString);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  async function handleApprove() {
    if (!editedValues.isInterviewPassed) {
      alert("The interview must be marked as passed before approval.");
      return;
    }

    setIsLoading(true);
    const functions = getFunctions();
    const createUserFunction = httpsCallable(functions, "createUser");

    try {
      // Prepare the user data
      console.log("User Retrieved:", userName);

      const userData = {
        email: applicationDetails.email,
        password: "unique1234",
        userData: {
          ...applicationDetails,
          notes: notes || "",
          applicationComplete: false,
          hiringDate: getCurrentFormattedDate(),
          approvedBy: userName,
        },
      };

      // Call the cloud function to create the user
      const result = await createUserFunction(userData);
      console.log("User created in Auth system:", result.data.uid);

      // Delete the application from the applications collection
      console.log(
        "Attempting to delete application with ID:",
        applicationDetails.id
      );
      const applicationDocRef = doc(db, "applications", id);
      await deleteDoc(applicationDocRef);
      console.log("Application deleted successfully.");

      // Success modal display
      document
        .getElementById("success-modal")
        .setAttribute("data-action", "approved");
      document.getElementById("action-text").textContent = "approved";
      document.getElementById("success-modal").style.display = "block";
    } catch (error) {
      console.error("Error during approval process:", error);
      document.getElementById("error-modal").style.display = "block";
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDelete() {
    setIsLoading(true);
    try {
      // Delete the application from the `applications` collection.
      const applicationDocRef = doc(db, "applications", id);
      await deleteDoc(applicationDocRef);

      // Display success message or handle the UI post deletion.
      console.log("Application deleted successfully.");
      // Redirect to dashboard or update the UI as needed.
      navigate("/dashboard/review");
    } catch (error) {
      console.error("Error deleting application: ", error);
      // Handle error UI here.
      document.getElementById("error-modal").style.display = "block";
    }
    setIsLoading(false);
  }

  async function handleReject() {
    setIsLoading(true);
    try {
      // 1. Retrieve all the data of the current application.
      const applicationData = applicationDetails;

      // 2. Save the retrieved data to the `rejected_applicants` collection in Firestore.
      const rejectedApplicantsRef = collection(db, "rejected_applicants");
      await addDoc(rejectedApplicantsRef, applicationData);

      // 3. Delete the application from the `applications` collection.
      const applicationDocRef = doc(db, "applications", id);
      await deleteDoc(applicationDocRef);

      document
        .getElementById("success-modal")
        .setAttribute("data-action", "rejected");
      document.getElementById("action-text").textContent = "rejected";
      document.getElementById("success-modal").style.display = "block";
    } catch (error) {
      console.error("Error processing rejection: ", error);
      document.getElementById("error-modal").style.display = "block";
    }
    setIsLoading(false);
  }

  function redirectToDashboard() {
    // Hide the success modal
    document.getElementById("success-modal").style.display = "none";

    // Navigate back to the last page
    navigate(-1);
  }

  function hideErrorModal() {
    // Hide the error modal
    document.getElementById("error-modal").style.display = "none";
  }

  async function handleBlur(fieldName) {
    setIsEditing((prev) => ({ ...prev, [fieldName]: false }));

    let valueToUpdate = editedValues[fieldName];

    // Check if the value is numeric and format if it's the 'rate' field
    if (fieldName === "rate") {
      valueToUpdate = !isNaN(parseFloat(valueToUpdate))
        ? parseFloat(valueToUpdate).toFixed(2)
        : "";
    }

    const applicationDocRef = doc(db, "applications", id);

    try {
      await updateDoc(applicationDocRef, {
        [fieldName]: valueToUpdate,
      });
      setApplicationDetails((prev) => ({
        ...prev,
        [fieldName]: valueToUpdate,
      }));
    } catch (error) {
      console.error(`Error updating ${fieldName}:`, error);
      // Provide feedback to user
    }
  }

  return (
    <div className="bg-[#1F2937] p-8 relative">
      {/* Header Section */}
      <div className="mb-8 flex justify-between items-center">
        <div>
          <Link
            to="/dashboard/review"
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-300 hover:text-white transition-colors"
          >
            ← Back to Applications
          </Link>
          <h1 className="text-lg font-bold text-white uppercase tracking-wider mt-4">
            Interview Details
          </h1>
          <p className="text-gray-400">Review and manage candidate information</p>
        </div>
        
        {/* Interview Status Badge */}
        <div className={`px-4 py-2 rounded-full ${
          editedValues.isInterviewPassed 
            ? "bg-green-100 text-green-800" 
            : "bg-yellow-100 text-yellow-800"
        }`}>
          <span className="font-bold">
            {editedValues.isInterviewPassed ? "Interview Passed" : "Interview Pending"}
          </span>
        </div>
      </div>

      {/* Main Content Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Personal Information Card */}
        <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 shadow-lg border border-white/20">
          <h2 className="text-white font-semibold mb-4">Personal Information</h2>
          <ul className="space-y-4">
            {/* Name Group */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              <li className="group">
                <div className="text-sm font-medium text-gray-400">First Name</div>
                {isEditing.firstName ? (
                  <input
                    type="text"
                    value={editedValues.firstName || ""}
                    onChange={(e) => setEditedValues(prev => ({...prev, firstName: e.target.value}))}
                    onBlur={() => handleBlur("firstName")}
                    className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                  />
                ) : (
                  <div 
                    onClick={() => setIsEditing(prev => ({...prev, firstName: true}))}
                    className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                  >
                    {applicationDetails.firstName || "Not provided"}
                  </div>
                )}
              </li>

              <li className="group">
                <div className="text-sm font-medium text-gray-400">Middle Name</div>
                {isEditing.middleName ? (
                  <input
                    type="text"
                    value={editedValues.middleName || ""}
                    onChange={(e) => setEditedValues(prev => ({...prev, middleName: e.target.value}))}
                    onBlur={() => handleBlur("middleName")}
                    className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                  />
                ) : (
                  <div 
                    onClick={() => setIsEditing(prev => ({...prev, middleName: true}))}
                    className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                  >
                    {applicationDetails.middleName || "Not provided"}
                  </div>
                )}
              </li>

              <li className="group">
                <div className="text-sm font-medium text-gray-400">Last Name</div>
                {isEditing.lastName ? (
                  <input
                    type="text"
                    value={editedValues.lastName || ""}
                    onChange={(e) => setEditedValues(prev => ({...prev, lastName: e.target.value}))}
                    onBlur={() => handleBlur("lastName")}
                    className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                  />
                ) : (
                  <div 
                    onClick={() => setIsEditing(prev => ({...prev, lastName: true}))}
                    className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                  >
                    {applicationDetails.lastName || "Not provided"}
                  </div>
                )}
              </li>
            </div>

            {/* Contact Information */}
            <li className="group">
              <div className="text-sm font-medium text-gray-400">Email</div>
              {isEditing.email ? (
                <input
                  type="email"
                  value={editedValues.email || ""}
                  onChange={(e) => setEditedValues(prev => ({...prev, email: e.target.value}))}
                  onBlur={() => handleBlur("email")}
                  className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                />
              ) : (
                <div 
                  onClick={() => setIsEditing(prev => ({...prev, email: true}))}
                  className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                >
                  {applicationDetails.email || "Not provided"}
                </div>
              )}
            </li>

            <li className="group">
              <div className="text-sm font-medium text-gray-400">Phone Number</div>
              {isEditing.phone ? (
                <input
                  type="tel"
                  value={editedValues.phone || ""}
                  onChange={(e) => setEditedValues(prev => ({...prev, phone: e.target.value}))}
                  onBlur={() => handleBlur("phone")}
                  className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                />
              ) : (
                <div 
                  onClick={() => setIsEditing(prev => ({...prev, phone: true}))}
                  className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                >
                  {applicationDetails.phone || "Not provided"}
                </div>
              )}
            </li>

            <li className="group">
              <div className="text-sm font-medium text-gray-400">Birth Date</div>
              {isEditing.birthDate ? (
                <DatePicker
                  selected={editedValues.birthDate ? new Date(editedValues.birthDate) : null}
                  onChange={(date) => {
                    setEditedValues(prev => ({...prev, birthDate: date}));
                    handleBlur("birthDate");
                  }}
                  className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                />
              ) : (
                <div 
                  onClick={() => setIsEditing(prev => ({...prev, birthDate: true}))}
                  className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                >
                  {applicationDetails.birthDate ? 
                    `${applicationDetails.birthDate} (Age: ${calculateAge(applicationDetails.birthDate)})` : 
                    "Not provided"}
                </div>
              )}
            </li>

            {/* Address Group */}
            <li className="group">
              <div className="text-sm font-medium text-gray-400">Address</div>
              {isEditing.address ? (
                <input
                  type="text"
                  value={editedValues.address || ""}
                  onChange={(e) => setEditedValues(prev => ({...prev, address: e.target.value}))}
                  onBlur={() => handleBlur("address")}
                  className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                />
              ) : (
                <div 
                  onClick={() => setIsEditing(prev => ({...prev, address: true}))}
                  className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                >
                  {applicationDetails.address || "Not provided"}
                </div>
              )}
            </li>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <li className="group">
                <div className="text-sm font-medium text-gray-400">City</div>
                {isEditing.city ? (
                  <input
                    type="text"
                    value={editedValues.city || ""}
                    onChange={(e) => setEditedValues(prev => ({...prev, city: e.target.value}))}
                    onBlur={() => handleBlur("city")}
                    className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                  />
                ) : (
                  <div 
                    onClick={() => setIsEditing(prev => ({...prev, city: true}))}
                    className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                  >
                    {applicationDetails.city || "Not provided"}
                  </div>
                )}
              </li>

              <li className="group">
                <div className="text-sm font-medium text-gray-400">State</div>
                {isEditing.state ? (
                  <input
                    type="text"
                    value={editedValues.state || ""}
                    onChange={(e) => setEditedValues(prev => ({...prev, state: e.target.value}))}
                    onBlur={() => handleBlur("state")}
                    className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                  />
                ) : (
                  <div 
                    onClick={() => setIsEditing(prev => ({...prev, state: true}))}
                    className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                  >
                    {applicationDetails.state || "Not provided"}
                  </div>
                )}
              </li>

              <li className="group">
                <div className="text-sm font-medium text-gray-400">Zip</div>
                {isEditing.zip ? (
                  <input
                    type="text"
                    value={editedValues.zip || ""}
                    onChange={(e) => setEditedValues(prev => ({...prev, zip: e.target.value}))}
                    onBlur={() => handleBlur("zip")}
                    className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                  />
                ) : (
                  <div 
                    onClick={() => setIsEditing(prev => ({...prev, zip: true}))}
                    className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                  >
                    {applicationDetails.zip || "Not provided"}
                  </div>
                )}
              </li>
            </div>
          </ul>
        </div>

        {/* Employment Information Card */}
        <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 shadow-lg border border-white/20">
          <h2 className="text-white font-semibold mb-4">Employment Details</h2>
          <ul className="space-y-4">
            <li className="group">
              <div className="text-sm font-medium text-gray-400">Job Type</div>
              {isEditing.jobType ? (
                <select
                  value={editedValues.jobType || ""}
                  onChange={(e) => setEditedValues(prev => ({...prev, jobType: e.target.value}))}
                  onBlur={() => handleBlur("jobType")}
                  className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                >
                  <option value="full_time">Full Time</option>
                  <option value="part_time">Part Time</option>
                  <option value="temporary">Temporary</option>
                </select>
              ) : (
                <div 
                  onClick={() => setIsEditing(prev => ({...prev, jobType: true}))}
                  className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer capitalize"
                >
                  {(applicationDetails.jobType || "Not provided").replace('_', ' ')}
                </div>
              )}
            </li>

            <li className="group">
              <div className="text-sm font-medium text-gray-400">Availability</div>
              {isEditing.availability ? (
                <textarea
                  value={editedValues.availability || ""}
                  onChange={(e) => setEditedValues(prev => ({...prev, availability: e.target.value}))}
                  onBlur={() => handleBlur("availability")}
                  className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                  rows="3"
                />
              ) : (
                <div 
                  onClick={() => setIsEditing(prev => ({...prev, availability: true}))}
                  className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                >
                  {applicationDetails.availability || "Not provided"}
                </div>
              )}
            </li>

            <li className="group">
              <div className="text-sm font-medium text-gray-400">Restrictions</div>
              {isEditing.restrictions ? (
                <textarea
                  value={editedValues.restrictions || ""}
                  onChange={(e) => setEditedValues(prev => ({...prev, restrictions: e.target.value}))}
                  onBlur={() => handleBlur("restrictions")}
                  className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                  rows="3"
                />
              ) : (
                <div 
                  onClick={() => setIsEditing(prev => ({...prev, restrictions: true}))}
                  className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                >
                  {applicationDetails.restrictions || "Not provided"}
                </div>
              )}
            </li>

            <li className="group">
              <div className="text-sm font-medium text-gray-400">Rate ($)</div>
              {isEditing.rate ? (
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  value={editedValues.rate || ""}
                  onChange={(e) => setEditedValues(prev => ({...prev, rate: e.target.value}))}
                  onBlur={() => handleBlur("rate")}
                  className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                />
              ) : (
                <div 
                  onClick={() => setIsEditing(prev => ({...prev, rate: true}))}
                  className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                >
                  {applicationDetails.rate ? `$${applicationDetails.rate}` : "Not provided"}
                </div>
              )}
            </li>
          </ul>
        </div>

        {/* Documentation & Skills Card */}
        <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 shadow-lg border border-white/20">
          <h2 className="text-white font-semibold mb-4">Documentation & Skills</h2>
          <ul className="space-y-4">
            <li className="group">
              <div className="text-sm font-medium text-gray-400">Identification Type</div>
              {isEditing.identificationType ? (
                <select
                  value={editedValues.identificationType || "none"}
                  onChange={(e) => setEditedValues(prev => ({...prev, identificationType: e.target.value}))}
                  onBlur={() => handleBlur("identificationType")}
                  className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                >
                  <option value="none">Select Type</option>
                  <option value="ssn">SSN</option>
                  <option value="ein">EIN</option>
                  <option value="itin">ITIN</option>
                </select>
              ) : (
                <div 
                  onClick={() => setIsEditing(prev => ({...prev, identificationType: true}))}
                  className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer uppercase"
                >
                  {applicationDetails.identificationType || "Not provided"}
                </div>
              )}
            </li>

            <li className="group">
              <div className="text-sm font-medium text-gray-400">Has License</div>
              {isEditing.hasLicense ? (
                <select
                  value={editedValues.hasLicense.toString()}
                  onChange={(e) => setEditedValues(prev => ({...prev, hasLicense: e.target.value === 'true'}))}
                  onBlur={() => handleBlur("hasLicense")}
                  className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              ) : (
                <div 
                  onClick={() => setIsEditing(prev => ({...prev, hasLicense: true}))}
                  className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                >
                  {applicationDetails.hasLicense ? "Yes" : "No"}
                </div>
              )}
            </li>

            <li className="group">
              <div className="text-sm font-medium text-gray-400">Speaks English</div>
              {isEditing.speaksEnglish ? (
                <select
                  value={editedValues.speaksEnglish.toString()}
                  onChange={(e) => setEditedValues(prev => ({...prev, speaksEnglish: e.target.value === 'true'}))}
                  onBlur={() => handleBlur("speaksEnglish")}
                  className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              ) : (
                <div 
                  onClick={() => setIsEditing(prev => ({...prev, speaksEnglish: true}))}
                  className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer"
                >
                  {applicationDetails.speaksEnglish ? "Yes" : "No"}
                </div>
              )}
            </li>

            {applicationDetails.speaksEnglish && (
              <li className="group">
                <div className="text-sm font-medium text-gray-400">English Proficiency Level</div>
                {isEditing.englishProficiencyLevel ? (
                  <select
                    value={editedValues.englishProficiencyLevel || "basic"}
                    onChange={(e) => setEditedValues(prev => ({...prev, englishProficiencyLevel: e.target.value}))}
                    onBlur={() => handleBlur("englishProficiencyLevel")}
                    className="w-full bg-white/5 border border-white/20 rounded px-3 py-2 text-white focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="basic">Basic</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="advanced">Advanced</option>
                    <option value="fluent">Fluent</option>
                  </select>
                ) : (
                  <div 
                    onClick={() => setIsEditing(prev => ({...prev, englishProficiencyLevel: true}))}
                    className="text-white group-hover:bg-white/5 rounded px-3 py-2 cursor-pointer capitalize"
                  >
                    {applicationDetails.englishProficiencyLevel || "Not provided"}
                  </div>
                )}
              </li>
            )}
          </ul>
        </div>

        {/* Notes Section - Full Width */}
        <div className="lg:col-span-3 bg-white/10 backdrop-blur-sm rounded-lg p-6 shadow-lg border border-white/20">
          <label className="block text-white font-semibold mb-4">
            Interviewer Notes
          </label>
          <textarea
            className="w-full bg-white/5 border border-white/20 rounded-lg px-4 py-3 text-white focus:ring-2 focus:ring-blue-500"
            value={notes}
            onChange={handleNotesChange}
            rows="5"
            placeholder="Enter your notes here..."
          />
          <span className="block mt-2 text-sm text-gray-400">{saveStatus}</span>
        </div>

        {/* Action Buttons - Full Width */}
        <div className="lg:col-span-3 flex justify-end space-x-4">
          <button
            onClick={handleInterviewPassed}
            disabled={isLoading || editedValues.isInterviewPassed}
            className="px-4 py-2 rounded-lg text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            <FontAwesomeIcon icon={faCheck} />
            <span>Mark Interview Passed</span>
          </button>
          
          <button
            onClick={handleReject}
            disabled={isLoading}
            className="px-4 py-2 rounded-lg text-white bg-orange-600 hover:bg-orange-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            <FontAwesomeIcon icon={faX} />
            <span>Reject</span>
          </button>
          
          <button
            onClick={handleDelete}
            disabled={isLoading}
            className="px-4 py-2 rounded-lg text-white bg-red-600 hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            <FontAwesomeIcon icon={faTrash} />
            <span>Delete</span>
          </button>
          
          <button
            onClick={handleApprove}
            disabled={isLoading || !editedValues.isInterviewPassed}
            className="px-4 py-2 rounded-lg text-white bg-green-600 hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            <FontAwesomeIcon icon={faCheck} />
            <span>Approve</span>
          </button>
        </div>
      </div>

      {/* Success Modal */}
      <div
        id="success-modal"
        tabindex="-1"
        data-action=""
        class="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div class="relative w-full max-w-md max-h-full mx-auto">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              onClick={() => redirectToDashboard()}
              type="button"
              class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
            <div class="p-6 text-center">
              <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Application successfully <span id="action-text">action</span>!
              </h3>
              <button
                onClick={() => redirectToDashboard()}
                type="button"
                class="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
              >
                Back to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Error Modal */}
      <div
        id="error-modal"
        tabindex="-1"
        class="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div class="relative w-full max-w-md max-h-full mx-auto">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              onClick={() => hideErrorModal()}
              type="button"
              class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
            <div class="p-6 text-center">
              <h3 class="mb-5 text-lg font-normal text-red-500 dark:text-red-400">
                It appears that this user already exists in the Hub.
              </h3>
              <p class="mb-4">
                To avoid duplication, please update the existing user's
                information instead of adding a new entry.
              </p>
              <button
                onClick={() => hideErrorModal()}
                type="button"
                class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EmployeeInterview;
