import React, { useMemo, useState, useCallback } from "react";
import { db } from "./firebase";
import { collection, query, getDocs, where } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import Select from "react-select";
import { motion } from "framer-motion";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

const Compendium = () => {
  const [filters, setFilters] = useState({
    status: ["Active"],
    searchTerm: "",
    selectedFields: [
      "firstName",
      "lastName",
      "companyName",
      "workLocation",
      "position",
      "payMethod",
      "salary",
      "email",
      "phone",
      "status",
    ],
  });

  const fetchEmployees = async () => {
    const usersRef = collection(db, "users");
    const q = query(
      usersRef,
      where(
        "status",
        "in",
        filters.status.length > 0
          ? filters.status
          : ["Active", "inactive", "terminated"]
      )
    );
    const querySnapshot = await getDocs(q);
    const employeeData = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        firstName: data.firstName || "Unknown",
        lastName: data.lastName || "Unknown",
        companyName: data.companyName || "",
        workLocation: data.workLocation || "Unknown",
        payMethod: data.payMethod || "Unknown",
        salary: parseFloat(data.salary) || 0,
        rate: parseFloat(data.rate) || 0,
        weeklySalary: parseFloat(data.salary)
          ? parseFloat(data.salary) / 52
          : 0,
        account: data.account || "",
        bank: data.bank || "",
        routing: data.routing || "",
        position: data.position || "",
        email: data.email || "",
        phone: data.phone || "",
        address: data.address || "",
        city: data.city || "",
        state: data.state || "",
        zip: data.zip || "",
        hiringDate: data.hiringDate || "",
        status: data.status || "",
        ein: data.ein || "",
        itin: data.itin || "",
        socialSecurity: data.socialSecurity || "",
        notes: data.notes || "",
        careerEvolution: data.careerEvolution || "",
        specialAbilities: data.specialAbilities || "",
      };
    });

    return employeeData.sort((a, b) => {
      const aName = a.companyName || `${a.firstName} ${a.lastName}`;
      const bName = b.companyName || `${b.firstName} ${b.lastName}`;
      return aName.localeCompare(bName);
    });
  };

  const {
    data: employees,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["employees", filters.status],
    queryFn: fetchEmployees,
  });

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    // Create header row using selected fields' labels
    const wsHeader = [
      filters.selectedFields.map(
        (field) =>
          availableFields.find((f) => f.value === field)?.label || field
      ),
    ];

    // Use tableData instead of employees to respect the search filter
    const wsData = tableData.map((employee) =>
      filters.selectedFields.map((field) => {
        const value = employee[field];

        // Format specific fields
        if (field === "salary") {
          return formatCurrency(value);
        } else if (field === "weeklySalary") {
          return formatCurrency(value);
        } else if (field === "hiringDate") {
          return value ? new Date(value).toLocaleDateString() : "";
        } else {
          return value?.toString() || "";
        }
      })
    );

    const ws = XLSX.utils.aoa_to_sheet(wsHeader.concat(wsData));

    // Auto-size columns
    const colWidths = wsHeader[0].map((header, i) => {
      const maxLength = Math.max(
        header.length,
        ...wsData.map((row) => (row[i]?.toString() || "").length)
      );
      return { wch: maxLength + 2 }; // Add some padding
    });
    ws["!cols"] = colWidths;

    XLSX.utils.book_append_sheet(wb, ws, "Employee Data");

    const filename = `employee_data_${
      new Date().toISOString().split("T")[0]
    }.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  const [sorting, setSorting] = useState([
    {
      id: "firstName",
      desc: false,
    },
  ]);

  const availableFields = useMemo(
    () => [
      { value: "firstName", label: "First Name" },
      { value: "lastName", label: "Last Name" },
      { value: "companyName", label: "Company Name" },
      { value: "workLocation", label: "Work Location" },
      { value: "payMethod", label: "Pay Method" },
      { value: "salary", label: "Salary" },
      { value: "rate", label: "Rate" },
      { value: "position", label: "Position" },
      { value: "email", label: "Email" },
      { value: "phone", label: "Phone" },
      { value: "address", label: "Address" },
      { value: "city", label: "City" },
      { value: "state", label: "State" },
      { value: "zip", label: "ZIP" },
      { value: "bank", label: "Bank" },
      { value: "account", label: "Account" },
      { value: "routing", label: "Routing" },
      { value: "ein", label: "EIN" },
      { value: "itin", label: "ITIN" },
      { value: "socialSecurity", label: "Social Security" },
      { value: "hiringDate", label: "Hiring Date" },
      { value: "status", label: "Status" },
      { value: "notes", label: "Notes" },
      { value: "careerEvolution", label: "Career Evolution" },
      { value: "specialAbilities", label: "Special Abilities" },
    ],
    []
  );

  const handleStatusChange = useCallback((selectedStatuses) => {
    setFilters((prev) => ({ ...prev, status: selectedStatuses }));
  }, []);

  const handleSearchChange = useCallback((e) => {
    setFilters((prev) => ({ ...prev, searchTerm: e.target.value }));
  }, []);

  const handleFieldsChange = useCallback((selected) => {
    if (!selected || selected.length === 0) {
      return; // Prevent empty selection
    }

    setFilters((prev) => ({
      ...prev,
      selectedFields: selected.map((option) => option.value),
    }));
  }, []);

  const columns = useMemo(() => {
    const defaultColumn = {
      header: "Name",
      accessorKey: "firstName",
      cell: (info) => info.getValue()?.toString() || "",
    };

    if (!filters.selectedFields.length) {
      return [defaultColumn];
    }

    return filters.selectedFields.map((field) => ({
      header: availableFields.find((f) => f.value === field)?.label || field,
      accessorKey: field,
      cell: (info) => {
        const value = info.getValue();
        if (field === "salary") {
          return formatCurrency(value);
        }
        return value?.toString() || "";
      },
    }));
  }, [filters.selectedFields, availableFields]);

  const tableData = useMemo(() => {
    if (!employees) return [];
    return employees.filter((employee) => {
      if (!filters.searchTerm) return true;
      return filters.selectedFields.some((field) => {
        const value = employee[field];
        return value
          ?.toString()
          .toLowerCase()
          .includes(filters.searchTerm.toLowerCase());
      });
    });
  }, [employees, filters.searchTerm, filters.selectedFields]);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  if (error) {
    return (
      <div className="bg-[#1F2937] min-h-screen text-white p-8">
        Error: {error.message}
      </div>
    );
  }

  return (
    <div
      className="container mx-auto px-4 sm:px-8"
      style={{ width: "calc(100vw - 320px)" }}
    >
      <div className="flex flex-col h-full max-w-full">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold text-white">User Management</h1>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={exportToExcel}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg flex items-center transition-colors duration-200"
          >
            <FontAwesomeIcon icon={faFileExport} className="mr-2" /> Export to
            Excel
          </motion.button>
        </div>

        <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-semibold text-gray-300 mb-2">
                Status
              </label>
              <Select
                isMulti
                value={filters.status.map((status) => ({
                  value: status,
                  label: status,
                }))}
                onChange={(selected) =>
                  handleStatusChange(
                    selected ? selected.map((option) => option.value) : []
                  )
                }
                options={[
                  { value: "Active", label: "Active" },
                  { value: "inactive", label: "Inactive" },
                  { value: "terminated", label: "Terminated" },
                ]}
                className="text-black"
                classNamePrefix="select"
              />
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-300 mb-2">
                Columns
              </label>
              <Select
                isMulti
                value={filters.selectedFields.map((field) =>
                  availableFields.find((f) => f.value === field)
                )}
                onChange={handleFieldsChange}
                options={availableFields}
                className="text-black"
                classNamePrefix="select"
                isOptionDisabled={() => filters.selectedFields.length <= 1}
              />
            </div>
          </div>

          <div className="mt-4">
            <label className="block text-sm font-semibold text-gray-300 mb-2">
              Search
            </label>
            <div className="relative">
              <input
                type="text"
                value={filters.searchTerm}
                onChange={handleSearchChange}
                placeholder="Search..."
                className="w-full p-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              />
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="w-full">
            <div className="mb-4">
              {[...Array(8)].map((_, idx) => (
                <div key={idx} className="flex space-x-4 mb-4">
                  {[...Array(filters.selectedFields.length)].map((_, cellIdx) => (
                    <Skeleton 
                      key={cellIdx}
                      width={150}
                      height={20}
                      baseColor="#374151"
                      highlightColor="#4B5563"
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        ) : employees && employees.length === 0 ? (
          <p className="text-white text-center">No employees found.</p>
        ) : (
          <div
            className="w-full overflow-x-auto"
            style={{ maxWidth: "calc(100vw - 352px)" }}
          >
            <div className="align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                            className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            <span>
                              {{
                                asc: " 🔼",
                                desc: " 🔽",
                              }[header.column.getIsSorted()] ?? null}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className="hover:bg-gray-100 transition-colors"
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className="px-5 py-5 border-b border-gray-200 text-sm whitespace-nowrap"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Compendium;
