import React, { useState, useEffect, useContext } from "react";
import { GoogleMapsContext } from "./GoogleMapsProvider";

function LocationsList({
  locations,
  onEditLocation,
  onLocationClick,
  selectedLocation,
  userRole,
}) {
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });
  const [autocomplete, setAutocomplete] = useState(null);

  const { isLoaded } = useContext(GoogleMapsContext);

  useEffect(() => {
    if (
      isLoaded &&
      window.google &&
      window.google.maps &&
      window.google.maps.places
    ) {
      setAutocomplete(
        new window.google.maps.places.Autocomplete(
          document.getElementById("locationInput"),
          { types: ["geocode"] }
        )
      );
    }
  }, [isLoaded]);

  const sortedLocations = [...locations].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleEditLocation = (location) => {
    if (autocomplete) {
      onEditLocation(location);
    } else {
      console.error("Autocomplete is not available yet");
      // You might want to show a user-friendly message here
    }
  };

  return (
    <div className="overflow-x-auto rounded-lg">
      <table className="min-w-full leading-normal">
        <thead>
          <tr>
            <th
              className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
              onClick={() => requestSort("name")}
            >
              <div className="flex justify-between items-center">
                Name/Address
              </div>
            </th>
            <th
              className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
              onClick={() => requestSort("radius")}
            >
              Radius
            </th>
            {userRole !== "consultant" && userRole !== "director" && (
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {sortedLocations.map((location) => (
            <tr
              key={location.id}
              className={`cursor-pointer ${
                selectedLocation === location.name ? "bg-blue-100" : ""
              }`}
              onClick={() => onLocationClick(location)}
            >
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <div className="flex items-center">
                  <div className="ml-3">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {location.name}
                    </p>
                    <p className="text-gray-900 whitespace-no-wrap">
                      {location.address}
                    </p>
                  </div>
                </div>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">
                  {location.radius}
                </p>
              </td>
              {userRole !== "consultant" && userRole !== "director" && (
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditLocation(location);
                    }}
                    className="px-4 py-2 bg-blue-500 font-bold text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                    title="Edit"
                  >
                    Edit
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LocationsList;
