/**
 * Calculates the duration of each role in a shift, accounting for breaks
 * @param {Date} startTimestamp - Shift start time
 * @param {Date} endTimestamp - Shift end time
 * @param {Array} roleChanges - Array of role changes with timestamps
 * @param {Array} breakTimestamps - Array of break start/end timestamps
 * @returns {Object} Object with roles as keys and duration in hours as values
 */
export const calculateRoleHours = (
  startTimestamp,
  endTimestamp,
  roleChanges,
  breakTimestamps
) => {
  const start = startTimestamp ? startTimestamp.toDate() : null;
  const end = endTimestamp ? endTimestamp.toDate() : new Date();
  if (!start) return {};

  const rolesDuration = {};
  let previousTimestamp = start;

  roleChanges.forEach((roleChange, index) => {
    const currentRole = roleChange.role;
    let nextTimestamp = index < roleChanges.length - 1
      ? roleChanges[index + 1].timestamp.toDate()
      : end;

    let roleDuration = nextTimestamp - previousTimestamp;

    if (breakTimestamps && breakTimestamps.length > 1) {
      for (let i = 0; i < breakTimestamps.length; i += 2) {
        const breakStart = new Date(breakTimestamps[i]);
        const breakEnd = breakTimestamps[i + 1]
          ? new Date(breakTimestamps[i + 1])
          : new Date();
        
        if (breakStart >= previousTimestamp && breakEnd <= nextTimestamp) {
          roleDuration -= breakEnd - breakStart;
        }
      }
    }

    const roleHours = roleDuration / (1000 * 60 * 60);
    rolesDuration[currentRole] = (rolesDuration[currentRole] || 0) + roleHours;

    previousTimestamp = nextTimestamp;
  });

  return rolesDuration;
};

/**
 * Converts decimal hours to HH:MM format
 * @param {number} hours - Hours in decimal format
 * @returns {string} Time in HH:MM format
 */
export const convertHoursToHM = (hours) => {
  const totalMinutes = hours * 60;
  const hoursPart = Math.floor(totalMinutes / 60);
  const minutesPart = Math.floor(totalMinutes % 60);
  return `${hoursPart}:${minutesPart.toString().padStart(2, "0")}`;
};
