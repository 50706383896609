import React, { useState, useEffect, useCallback } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import ReportingUsers from "./ReportingUsers";
import { db } from "./firebase";
import {
  getDocs,
  collection,
  doc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faFloppyDisk,
  faCheck,
  faExclamationTriangle,
  faCalendarAlt,
  faEyeSlash,
  faEye,
  faUpload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getAuth } from "firebase/auth";

const EmployeeModal = ({
  selectedEmployee,
  isEditing,
  handleCloseModal,
  handleSave,
  handleInputChange,
  setIsEditing,
  isLoading,
  alert,
  updateSelectedEmployee,
  userRole,
}) => {
  const positions = [
    "Supervisor - Hertz",
    "Supervisor - Sixt",
    "Supervisor - Avis",
    "Supervisor - TCR",
    "Supervisor - Dollar/Thrifty",
    "VSA - Sixt",
    "Fleet Driver - Sixt",
    "Driver - Sixt",
    "Greeter - Sixt",
    "Shuttle Driver - Sixt",
    "Flex Rep - Sixt",
    "Detail - Hertz",
    "Detail - Sixt",
    "Janitorial - Hertz",
    "Janitorial - Sixt",
    "Driver - Hertz",
    "Counter - Hertz",
    "Lot Att - Avis",
    "Lot Att - Sixt",
    "IR - Hertz",
    "ADM - Hertz",
    "Greeter - Hertz",
    "VSA - Hertz",
    "Overnight VSA - Hertz",
    "Overnight Driver - Hertz",
    "Exit Gate - Hertz",
    "Transporters - Hertz",
    "Triage - Hertz",
    "CSR - Hertz",
    "Quality Control Inspector - Hertz",
    "Driver Back - Hertz",
    "Bus Driver - Hertz",
    "Flex Rep - Hertz",
    "Technician - Hertz",
    "Vehicle Mechanic - Amazon",
    "Geotab - Hertz",
    "VSA - Avis",
    "Driver - Avis",
    "Counter/IR - Avis",
    "Exit Gate - Avis",
    "Room Parts Assistant - TCR",
    "Front Desk - Office",
    "Administrative Assistant - Office",
    "General Services - Shop",
    "Detailer - Shop",
    "Electrician - Shop",
    "Supervisor - Drivo",
    "Rental Agent - Drivo",
    "Inspection Agent - Drivo",
    "VSA - Drivo",
    "Shuttle Driver - Drivo",
  ].map((pos) => ({ value: pos, label: pos }));
  const roleOptions = [
    { value: "employee", label: "employee" },
    { value: "mobile", label: "mobile" },
    { value: "supervisor", label: "supervisor" },
    { value: "director", label: "director" },
    { value: "recruiter", label: "recruiter" },
    { value: "HR", label: "HR" },
    { value: "consultant", label: "consultant" },
    { value: "admin", label: "admin" },
    { value: "manager", label: "manager" },
    { value: "owner", label: "owner" },
  ];
  const [isSSNVisible, setSSNVisible] = useState(false);
  const [isITINVisible, setITINVisible] = useState(false);
  const [isDriversLicenseVisible, setDriversLicenseVisible] = useState(false);
  const [locations, setLocations] = useState([]);
  const [activeSupervisors, setActiveSupervisors] = useState([]);
  const [idUploadProgress, setIdUploadProgress] = useState(0);
  const [documentUploadProgress, setDocumentUploadProgress] = useState(0);
  const [displayedSupervisorNames, setDisplayedSupervisorNames] = useState("");
  const [emailInput, setEmailInput] = useState(selectedEmployee.email || "");
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailUpdateStatus, setEmailUpdateStatus] = useState(null);
  const [documentDeleteProgress, setDocumentDeleteProgress] = useState(0);
  const [isEINVisible, setEINVisible] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [activeTab, setActiveTab] = useState("details");

  useEffect(() => {
    if (selectedEmployee) {
      setEmployeeId(selectedEmployee.uid);
    }
  }, [selectedEmployee]);

  const customStyles = {
    option: (provided, state) => {
      const optionColor =
        statusOptions.find((opt) => opt.value === state.data.value)
          ?.colorClass || "bg-gray-500";
      return {
        ...provided,
        backgroundColor: state.isSelected
          ? "lightgray"
          : state.isFocused
          ? optionColor
          : null,
        color: state.isSelected ? "black" : state.isFocused ? "blue" : "black",
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: "black",
      };
    },
  };

  const fetchActiveSupervisors = useCallback(async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("status", "==", "Active"),
          where("role", "in", ["supervisor", "manager"])
        )
      );
      const fetchedSupervisors = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          value: doc.id,
          label: `${data.firstName} ${
            data.middleName ? data.middleName + " " : ""
          }${data.lastName} (${data.role})`,
        };
      });

      // Sort the supervisors and managers alphabetically by label
      const sortedSupervisors = fetchedSupervisors.sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      setActiveSupervisors(sortedSupervisors);
    } catch (error) {
      console.error("Error fetching active supervisors and managers:", error);
    }
  }, []);

  useEffect(() => {
    fetchActiveSupervisors();
  }, [fetchActiveSupervisors]);

  const toggleEINVisibility = () => {
    setEINVisible(!isEINVisible);
  };

  const displayReportsToNames = useCallback(
    (reportsToUIDs) => {
      // Check if reportsToUIDs is invalid
      if (!reportsToUIDs) {
        return "N/A";
      }

      // If reportsToUIDs is a string, return it directly
      if (typeof reportsToUIDs === "string") {
        return reportsToUIDs;
      }

      // Check if reportsToUIDs is an array and process it
      if (Array.isArray(reportsToUIDs)) {
        return reportsToUIDs
          .map((uid) => {
            const supervisor = activeSupervisors.find(
              (sup) => sup.value === uid
            );
            return supervisor ? supervisor.label : "Unknown";
          })
          .join(", ");
      }

      // Log a warning if reportsToUIDs is of an unexpected type
      console.warn(
        "Expected reportsToUIDs to be an array or a string, but got:",
        reportsToUIDs
      );
      return "Unknown";
    },
    [activeSupervisors]
  );

  useEffect(() => {
    if (selectedEmployee && selectedEmployee.reportsTo) {
      const names = displayReportsToNames(selectedEmployee.reportsTo);
      setDisplayedSupervisorNames(names);
    }
  }, [selectedEmployee, activeSupervisors, displayReportsToNames]);

  useEffect(() => {
    setEmailInput(selectedEmployee.email || "");
  }, [selectedEmployee]);

  const renderRateSection = () => {
    if (isEditing && userRole === "owner") {
      return (
        <div className="flex items-center my-2">
          <label className="mr-2 w-1/4">Rate:</label>
          <div className="flex items-center border rounded h-9 w-80 p-1.5">
            <span className="mr-2">$</span>
            <input
              type="number"
              step="0.01"
              value={selectedEmployee.rate || ""}
              onChange={(e) => handleInputChange("rate", e.target.value)}
              onBlur={(e) =>
                handleInputChange("rate", parseFloat(e.target.value).toFixed(2))
              }
              placeholder="0.00"
              className="flex-1 outline-none"
            />
          </div>
        </div>
      );
    } else if (userRole === "manager" || userRole === "owner") {
      return (
        <p>
          Rate: $
          {selectedEmployee.rate
            ? parseFloat(selectedEmployee.rate).toFixed(2)
            : "N/A"}
        </p>
      );
    }
  };

  const renderSalarySection = () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const canEditSalary = currentUser?.uid === "aX5iMnYE26hdEi5MN5eqSa6rOrP2";

    if (isEditing && canEditSalary) {
      return (
        <div className="flex items-center my-2">
          <label className="mr-2 w-1/4">Salary:</label>
          <div className="flex items-center border rounded h-9 w-80 p-1.5">
            <span className="mr-2">$</span>
            <input
              type="number"
              step="0.01"
              value={selectedEmployee.salary || ""}
              onChange={(e) => handleInputChange("salary", e.target.value)}
              onBlur={(e) => {
                const value = e.target.value.trim();
                handleInputChange(
                  "salary",
                  value ? parseFloat(value).toFixed(2) : ""
                );
              }}
              placeholder="0.00"
              className="flex-1 outline-none"
            />
          </div>
        </div>
      );
    } else if (userRole === "owner") {
      return (
        <p>
          Salary:{" "}
          {selectedEmployee.salary &&
          selectedEmployee.salary !== "" &&
          !isNaN(selectedEmployee.salary)
            ? `$${parseFloat(selectedEmployee.salary).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : "Not Salary"}
        </p>
      );
    }
  };

  const renderApprovedBySection = () => {
    if (selectedEmployee.approvedBy) {
      return <p>Approved By: {selectedEmployee.approvedBy}</p>;
    }
  };

  const renderApprovedNonCompete = () => {
    const hasSigned = selectedEmployee.hasSignedNonCompete;
    const timestamp = selectedEmployee.nonCompeteSignedDate;

    // Check if nonCompeteSignedDate is a Firestore Timestamp and convert it to Date
    const signDate = timestamp ? timestamp.toDate() : null;

    if (hasSigned) {
      return (
        <p>
          User has signed the NCA
          {signDate &&
            !isNaN(signDate.getTime()) &&
            ` on ${signDate.toLocaleDateString()}`}
          .
        </p>
      );
    } else {
      return <p>User has NOT signed the NCA.</p>;
    }
  };

  const renderTerminationDateSection = () => {
    if (userRole !== "supervisor") {
      return (
        <div>
          <label className="mr-2 block">Termination Date:</label>
          {isEditing &&
          (userRole === "manager" ||
            userRole === "owner" ||
            userRole === "admin") ? (
            <div className="flex items-center">
              <ReactDatePicker
                selected={
                  isValidDate(
                    new Date(selectedEmployee.terminationDate + "T00:00:00")
                  )
                    ? new Date(selectedEmployee.terminationDate + "T00:00:00")
                    : null
                }
                onChange={(date) => {
                  const formattedDate = date
                    ? new Date(date.setHours(0, 0, 0, 0))
                        .toISOString()
                        .split("T")[0]
                    : null;
                  handleInputChange("terminationDate", formattedDate);
                }}
                dateFormat="yyyy-MM-dd"
                placeholderText="Select a date"
                className="mr-2"
              />
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
          ) : (
            (userRole === "manager" ||
              userRole === "owner" ||
              userRole === "admin") && (
              <span>{selectedEmployee.terminationDate || "N/A"}</span>
            )
          )}
        </div>
      );
    }
    return null;
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  const renderStatusSection = () => {
    if (isEditing && userRole === "owner") {
      return (
        <>
          <label className="mr-2">Status:</label>
          <Select
            value={statusOptions.find(
              (option) =>
                option.value.toLowerCase() ===
                selectedEmployee.status.toLowerCase()
            )}
            onChange={(selectedOption) =>
              handleInputChange("status", selectedOption.value)
            }
            options={statusOptions}
            formatOptionLabel={(option) => (
              <>
                {option.label}
                <span
                  className={`ml-2 status-indicator ${option.colorClass}`}
                ></span>
              </>
            )}
            styles={customStyles}
          />
        </>
      );
    } else {
      return (
        <p>
          Status: {}
          {selectedEmployee.status
            ? selectedEmployee.status.charAt(0).toUpperCase() +
              selectedEmployee.status.slice(1)
            : "N/A"}
          <span
            className={`ml-2 status-indicator ${
              statusOptions.find(
                (option) =>
                  option.value.toLowerCase() ===
                  selectedEmployee.status.toLowerCase()
              )?.colorClass || "bg-gray-500"
            }`}
          ></span>
        </p>
      );
    }
  };

  const renderPayMethod = () => {
    if (userRole !== "supervisor") {
      if (isEditing && userRole === "owner") {
        return (
          <>
            <label className="mr-2">Pay Method:</label>
            <Select
              value={payMethods.find(
                (option) => option.value === selectedEmployee.payMethod
              )}
              onChange={(selectedOption) =>
                handleInputChange("payMethod", selectedOption.value)
              }
              options={payMethods}
              autoComplete="off"
            />
          </>
        );
      } else {
        return <p>Pay Method: {selectedEmployee.payMethod}</p>;
      }
    }
    return null;
  };

  const renderRoleSection = () => {
    if (isEditing && userRole === "owner") {
      return (
        <>
          <label className="mr-2">Role:</label>
          <Select
            value={roleOptions.find(
              (option) => option.value === selectedEmployee.role
            )}
            onChange={(selectedOption) =>
              handleInputChange("role", selectedOption.value)
            }
            options={roleOptions}
            className="my-2"
          />
        </>
      );
    } else {
      return <p>Role: {selectedEmployee.role}</p>;
    }
  };

  const handleDocumentFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file || file.type !== "application/pdf") {
      console.error("File type is not PDF");
      window.alert("Only PDF files are allowed for tax documents.");
      return;
    }

    setDocumentUploadProgress(0);

    const storage = getStorage();
    const fileRef = ref(
      storage,
      `tax-documents/${selectedEmployee.uid}/${file.name}`
    );

    try {
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setDocumentUploadProgress(progress);
        },
        (error) => {
          console.error("Error during upload:", error);
        },
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);

          const employeeDocRef = doc(db, "users", selectedEmployee.uid);
          await updateDoc(employeeDocRef, { documentUrl: url });

          // Use the passed function to update selectedEmployee state
          updateSelectedEmployee({ documentUrl: url });
        }
      );
    } catch (error) {
      console.error("Error setting up upload:", error);
    }
  };

  const handleIdFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file.type.startsWith("image/")) {
      console.error("File type is not an image");
      window.alert("Only image files are allowed for the driver's license.");
      return;
    }

    setIdUploadProgress(0);

    const storage = getStorage();
    const fileRef = ref(
      storage,
      `drivers-license/${selectedEmployee.uid}/${file.name}`
    );

    try {
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setIdUploadProgress(progress);
        },
        (error) => {
          console.error("Error during upload:", error);
        },
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);

          const employeeDocRef = doc(db, "users", selectedEmployee.uid);
          await updateDoc(employeeDocRef, { driversLicenseFileUrl: url });

          // Use updateSelectedEmployee here
          updateSelectedEmployee({ driversLicenseFileUrl: url });
          setIdUploadProgress(100);
        }
      );
    } catch (error) {
      console.error("Error setting up upload:", error);
    }
  };

  const fetchLocations = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "locations"));
      const fetchedLocations = querySnapshot.docs.map((doc) => {
        const locationData = doc.data();
        return {
          value: doc.id,
          label: locationData.name,
          reportsTo: locationData.reportsTo, // Add this line
        };
      });
      setLocations(fetchedLocations);
    } catch (error) {
      console.error("Error fetching locations:", error);
      // Handle errors, e.g., set an error state or message
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const handleLocationChange = async (selectedOption) => {
    handleInputChange("workLocation", selectedOption.label);

    try {
      const location = locations.find(
        (loc) => loc.value === selectedOption.value
      );
      if (location && location.reportsTo) {
        const allSupervisors = await fetchActiveSupervisors();
        const filteredSupervisors = allSupervisors.filter((sup) =>
          location.reportsTo.includes(sup.value)
        );
        setActiveSupervisors(filteredSupervisors);
      } else {
        setActiveSupervisors([]);
      }
    } catch (error) {
      console.error("Error fetching supervisors based on location:", error);
    }
  };

  const payMethods = [
    { value: "W2", label: "W2" },
    { value: "1099", label: "1099" },
    { value: "Direct Deposit", label: "Direct Deposit" },
    { value: "EIN", label: "EIN" },
  ];

  const toggleSSNVisibility = () => {
    setSSNVisible(!isSSNVisible);
  };

  const toggleITINVisibility = () => {
    setITINVisible(!isITINVisible);
  };

  const toggleDriversLicenseVisibility = () => {
    setDriversLicenseVisible(!isDriversLicenseVisible);
  };

  function formatPhoneNumber(phone) {
    if (!phone) return "";
    const cleaned = ("" + phone).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phone;
  }

  function capitalizeName(name) {
    if (!name) return name;

    const lowercasedName = name.toLowerCase();
    const exceptions = ["de", "da", "das", "do", "dos"];

    return lowercasedName
      .split(" ")
      .map((word) =>
        exceptions.includes(word)
          ? word
          : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(" ");
  }

  const handleEmailChange = async () => {
    if (!emailInput) {
      setEmailUpdateStatus({
        success: false,
        message: "Email cannot be empty",
      });
      return;
    }

    setEmailLoading(true);
    setEmailUpdateStatus(null);

    const uid = selectedEmployee.uid;

    try {
      const response = await fetch(
        `https://us-central1-uniquepunchcard-ab19f.cloudfunctions.net/updateUserEmailDirectly?uid=${uid}&newEmail=${encodeURIComponent(
          emailInput
        )}`
      );

      if (!response.ok) {
        throw new Error(`Failed to update email: ${response.statusText}`);
      }

      const result = await response.json();
      if (result.success) {
        handleInputChange("email", emailInput);
        setEmailUpdateStatus({
          success: true,
          message: "Email updated successfully",
        });
      } else {
        throw new Error(result.message || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error updating email:", error);
      setEmailUpdateStatus({ success: false, message: error.message });
    } finally {
      setEmailLoading(false);
    }
  };

  const statusOptions = [
    { value: "Active", label: "Active", colorClass: "status-active" },
    { value: "inactive", label: "Inactive", colorClass: "status-inactive" },
    {
      value: "terminated",
      label: "Terminated",
      colorClass: "status-terminated",
    },
  ];

  const handleDeleteIdFile = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this driver's license document?"
      )
    ) {
      const storage = getStorage();

      // Extract the correct file path from the URL
      const url = new URL(selectedEmployee.driversLicenseFileUrl);
      const decodedPath = decodeURIComponent(url.pathname);
      const fullPathSegments = decodedPath.split("/");
      const fileName = fullPathSegments[fullPathSegments.length - 1];
      const correctPath = `drivers-license/${selectedEmployee.uid}/${fileName}`;

      // Construct the correct file path
      const fileRef = ref(storage, correctPath);

      try {
        // Delete the file from storage
        await deleteObject(fileRef);

        // Update Firestore document
        const employeeDocRef = doc(db, "users", selectedEmployee.uid);
        await updateDoc(employeeDocRef, { driversLicenseFileUrl: null });

        // Update local state
        updateSelectedEmployee({ driversLicenseFileUrl: null });
      } catch (error) {
        console.error("Error deleting driver's license document:", error);
      }
    }
  };

  const renderBirthDateSection = () => {
    return (
      <div className="mb-2">
        <label className="mr-2 block">Birth Date:</label>
        {isEditing ? (
          <div className="flex items-center">
            <ReactDatePicker
              selected={
                isValidDate(new Date(selectedEmployee.birthDate + "T00:00:00"))
                  ? new Date(selectedEmployee.birthDate + "T00:00:00")
                  : null
              }
              onChange={(date) => {
                const formattedDate = date
                  ? new Date(date.setHours(0, 0, 0, 0))
                      .toISOString()
                      .split("T")[0]
                  : null;
                handleInputChange("birthDate", formattedDate);
              }}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select a birth date"
              className="mr-2"
              showYearDropdown
              dropdownMode="select"
              autoComplete="off"
            />
            <FontAwesomeIcon icon={faCalendarAlt} />
          </div>
        ) : (
          <span>{selectedEmployee.birthDate || "N/A"}</span>
        )}
      </div>
    );
  };

  const renderHiringDateSection = () => {
    return (
      <div className="mb-2">
        <label className="mr-2 block">Hiring Date:</label>
        {isEditing ? (
          <div className="flex items-center">
            <ReactDatePicker
              selected={
                isValidDate(new Date(selectedEmployee.hiringDate + "T00:00:00"))
                  ? new Date(selectedEmployee.hiringDate + "T00:00:00")
                  : null
              }
              onChange={(date) => {
                const formattedDate = new Date(date.setHours(0, 0, 0, 0))
                  .toISOString()
                  .split("T")[0];
                handleInputChange("hiringDate", formattedDate);
              }}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select a date"
              className="mr-2"
            />
            <FontAwesomeIcon icon={faCalendarAlt} />
          </div>
        ) : (
          <span>{selectedEmployee.hiringDate || "N/A"}</span>
        )}
      </div>
    );
  };

  const renderOptionalCompanyName = () => {
    return (
      <div className="mb-2 mt-2">
        <label className="mr-2 block">Company Name:</label>
        {isEditing ? (
          <div className="text-center mt-4">
            <input
              type="text"
              value={capitalizeName(selectedEmployee.companyName)}
              onChange={(e) => handleInputChange("companyName", e.target.value)}
              placeholder="Company Name"
              className="border rounded h-10 w-48 p-2 mx-1"
            />
          </div>
        ) : (
          <h2 className="text-center mt-4">{`${capitalizeName(
            selectedEmployee.companyName
          )} `}</h2>
        )}
      </div>
    );
  };

  const handleDeleteDocumentFile = async () => {
    if (window.confirm("Are you sure you want to delete this tax document?")) {
      const storage = getStorage();
      let decodedPath, correctPath;

      try {
        // Extract the correct file path from the URL
        const url = new URL(selectedEmployee.documentUrl);
        decodedPath = decodeURIComponent(url.pathname);

        // Extract only the relevant path after '/o/'
        const pathIndex = decodedPath.indexOf("/o/") + 3;
        correctPath = decodedPath.substring(pathIndex);

        // Construct the correct file path
        const fileRef = ref(storage, correctPath);

        // Indicate the deletion process has started
        setDocumentDeleteProgress(10); // Arbitrary start value

        // Delete the file from storage
        await deleteObject(fileRef);

        // Update Firestore document
        const employeeDocRef = doc(db, "users", selectedEmployee.uid);
        await updateDoc(employeeDocRef, { documentUrl: null });

        // Update local state
        updateSelectedEmployee({ documentUrl: null });

        // Indicate the deletion process has finished
        setDocumentDeleteProgress(100); // Arbitrary completion value

        // Reset progress after a short delay
        setTimeout(() => setDocumentDeleteProgress(0), 1000);
      } catch (error) {
        console.error("Error deleting tax document:", error);
        setDocumentDeleteProgress(0);
      }
    }
  };

  return (
    <div>
      <div className="fixed inset-0 bg-[#1F2937] z-50 flex justify-center items-start pt-4">
        <div className="bg-white rounded-lg w-3/4 max-h-[90vh] overflow-y-auto p-4 relative">
          <button
            onClick={handleCloseModal}
            className="absolute top-4 right-4 bg-red-500 text-white rounded-full p-2 hover:bg-red-700 transition duration-300 ease-in-out w-10 h-10 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          {userRole !== "supervisor" && (
            <button
              onClick={() => {
                if (isEditing) {
                  handleSave();
                }
                setIsEditing((prev) => !prev);
              }}
              className={`absolute top-4 right-16 text-white rounded-full p-2 transition duration-300 ease-in-out w-10 h-10 flex items-center justify-center ${
                isEditing
                  ? "bg-green-500 hover:bg-green-700"
                  : "bg-blue-500 hover:bg-blue-700"
              }`}
            >
              {isLoading ? (
                "..."
              ) : (
                <FontAwesomeIcon
                  icon={isEditing ? faFloppyDisk : faPenToSquare}
                />
              )}
            </button>
          )}

          {/* Icons for success and error */}
          {alert.type === "success" && (
            <div className="absolute top-4 right-28">
              <FontAwesomeIcon icon={faCheck} className="text-green-500" />
            </div>
          )}
          {alert.type === "error" && (
            <div className="absolute top-4 right-28">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-red-500"
              />
            </div>
          )}
          {/* Tab Navigation */}
          <div className="flex justify-center mb-3">
            <button
              onClick={() => setActiveTab("details")}
              className={`px-6 py-3 mx-2 rounded-lg transition-all duration-300 font-bold ${
                activeTab === "details"
                  ? "bg-blue-500 text-white shadow-lg"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300 hover:shadow-md"
              }`}
            >
              Employee Details
            </button>
            <button
              onClick={() => setActiveTab("reportingUsers")}
              className={`px-6 py-3 mx-2 rounded-lg transition-all duration-300 font-bold ${
                activeTab === "reportingUsers"
                  ? "bg-blue-500 text-white shadow-lg"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300 hover:shadow-md"
              }`}
            >
              Reporting Users
            </button>
          </div>
          {/* Tab Content */}
          <div>
            {activeTab === "details" && (
              <div>
                {/* Existing employee details code */}
                {selectedEmployee && (
                  <div className="flex flex-col md:flex-row mt-4 items-center justify-center">
                    <div className="md:w-1/3 px-4 text-center">
                      {userRole === "manager" || userRole === "owner" ? (
                        selectedEmployee.driversLicenseFileUrl ? (
                          <div>
                            <a
                              href={selectedEmployee.driversLicenseFileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={selectedEmployee.driversLicenseFileUrl}
                                alt="Driver's License"
                                className="rounded-lg mx-auto"
                                style={{
                                  maxWidth: "40%",
                                  height: "auto",
                                  objectFit: "contain",
                                }}
                              />
                            </a>
                            {isEditing && userRole === "owner" && (
                              <button
                                onClick={handleDeleteIdFile}
                                className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="mr-2"
                                />
                                Delete Driver's License
                              </button>
                            )}
                          </div>
                        ) : (
                          <p>No Driver's License on file.</p>
                        )
                      ) : selectedEmployee.driversLicenseFileUrl ? (
                        <p>Driver's License uploaded successfully.</p>
                      ) : (
                        <p>No Driver's License on file.</p>
                      )}

                      {!selectedEmployee.driversLicenseFileUrl && isEditing && (
                        <>
                          <button
                            onClick={() =>
                              document.getElementById("idFileInput").click()
                            }
                            className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                          >
                            <FontAwesomeIcon icon={faUpload} className="mr-2" />
                            Upload Driver's License
                          </button>
                          <input
                            type="file"
                            id="idFileInput"
                            onChange={handleIdFileChange}
                            accept="image/*"
                            className="hidden"
                          />
                          {idUploadProgress > 0 && (
                            <div className="w-full bg-gray-200 rounded-full mt-2">
                              <div
                                className="bg-blue-600 text-xs leading-none py-1 text-center text-white"
                                style={{ width: `${idUploadProgress}%` }}
                              >
                                {idUploadProgress.toFixed(0)}%
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {/* Tax Document Rendering */}
                      <div className="text-center mt-4">
                        {userRole === "manager" || userRole === "owner" ? (
                          selectedEmployee.documentUrl ? (
                            <div>
                              <p>
                                Tax Document:
                                <a
                                  href={selectedEmployee.documentUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="ml-4 px-4 py-2 bg-blue-500 font-bold text-white rounded hover:bg-blue-700"
                                >
                                  View Document
                                </a>
                              </p>
                              {isEditing && userRole === "owner" && (
                                <button
                                  onClick={handleDeleteDocumentFile}
                                  className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="mr-2"
                                  />
                                  Delete Tax Document
                                </button>
                              )}
                              {documentDeleteProgress > 0 && (
                                <div className="w-full bg-gray-200 rounded-full mt-2">
                                  <div
                                    className="bg-red-600 text-xs leading-none py-1 text-center text-white"
                                    style={{
                                      width: `${documentDeleteProgress}%`,
                                    }}
                                  >
                                    {documentDeleteProgress.toFixed(0)}%
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <p>No Tax Document on file.</p>
                          )
                        ) : selectedEmployee.documentUrl ? (
                          <p>Tax document uploaded successfully.</p>
                        ) : (
                          <p>No Tax Document on file.</p>
                        )}

                        {!selectedEmployee.documentUrl && isEditing && (
                          <>
                            <button
                              onClick={() =>
                                document
                                  .getElementById("taxDocumentInput")
                                  .click()
                              }
                              className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                              <FontAwesomeIcon
                                icon={faUpload}
                                className="mr-2"
                              />
                              Upload Tax Document
                            </button>
                            <input
                              type="file"
                              id="taxDocumentInput"
                              onChange={handleDocumentFileChange}
                              accept=".pdf"
                              className="hidden"
                            />
                            {documentUploadProgress > 0 && (
                              <div className="w-full bg-gray-200 rounded-full mt-2">
                                <div
                                  className="bg-blue-600 text-xs leading-none py-1 text-center text-white"
                                  style={{
                                    width: `${documentUploadProgress}%`,
                                  }}
                                >
                                  {documentUploadProgress.toFixed(0)}%
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {isEditing ? (
                        <div className="text-center mt-4">
                          <input
                            type="text"
                            value={capitalizeName(selectedEmployee.firstName)}
                            onChange={(e) =>
                              handleInputChange("firstName", e.target.value)
                            }
                            placeholder="First Name"
                            className="border rounded h-10 w-48 p-2 mx-1"
                          />
                          <input
                            type="text"
                            value={capitalizeName(selectedEmployee.middleName)}
                            onChange={(e) =>
                              handleInputChange("middleName", e.target.value)
                            }
                            placeholder="Middle Name"
                            className="border rounded h-10 w-48 p-2 mx-1"
                          />
                          <input
                            type="text"
                            value={capitalizeName(selectedEmployee.lastName)}
                            onChange={(e) =>
                              handleInputChange("lastName", e.target.value)
                            }
                            placeholder="Last Name"
                            className="border rounded h-10 w-48 p-2 mx-1"
                          />
                        </div>
                      ) : (
                        <h2 className="text-center mt-4">{`${capitalizeName(
                          selectedEmployee.firstName
                        )} ${capitalizeName(
                          selectedEmployee.middleName
                        )} ${capitalizeName(selectedEmployee.lastName)}`}</h2>
                      )}
                      {selectedEmployee.payMethod === "EIN" &&
                        renderOptionalCompanyName()}
                      {isEditing && userRole === "owner" && (
                        <div className="text-center mt-4">
                          <input
                            type="email"
                            value={emailInput}
                            onChange={(e) =>
                              setEmailInput(e.target.value.toLowerCase().trim())
                            }
                            placeholder="Enter new email"
                            className="border rounded h-10 w-48 p-2 mx-1"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                          />
                          <button
                            onClick={handleEmailChange}
                            className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            disabled={emailLoading}
                          >
                            {emailLoading ? "Updating..." : "Update Email"}
                          </button>
                          {emailUpdateStatus && (
                            <p
                              className={`text-center mt-2 ${
                                emailUpdateStatus.success
                                  ? "text-green-500"
                                  : "text-red-500"
                              }`}
                            >
                              {emailUpdateStatus.message}
                            </p>
                          )}
                        </div>
                      )}
                      <p className="text-center text-gray-500">
                        {selectedEmployee.email
                          ? selectedEmployee.email
                          : "N/A"}
                      </p>
                      {userRole !== "supervisor" &&
                        userRole !== "manager" &&
                        (isEditing ? (
                          <div className="text-center mt-4">
                            <input
                              type="text"
                              value={selectedEmployee.address}
                              onChange={(e) =>
                                handleInputChange("address", e.target.value)
                              }
                              placeholder="Address"
                              className="border rounded h-10 w-48 p-2 mx-1"
                            />
                          </div>
                        ) : (
                          <p className="text-center text-gray-500">
                            {selectedEmployee.address
                              ? selectedEmployee.address
                              : "N/A"}
                          </p>
                        ))}
                      {userRole !== "supervisor" &&
                        userRole !== "manager" &&
                        (isEditing ? (
                          <div className="text-center mt-4">
                            <input
                              type="text"
                              value={selectedEmployee.city}
                              onChange={(e) =>
                                handleInputChange("city", e.target.value)
                              }
                              placeholder="City"
                              className="border rounded h-10 w-48 p-2 mx-1"
                            />
                          </div>
                        ) : (
                          <p className="text-center text-gray-500">
                            {selectedEmployee.city
                              ? selectedEmployee.city
                              : "N/A"}
                          </p>
                        ))}
                      {userRole !== "supervisor" &&
                        userRole !== "manager" &&
                        (isEditing ? (
                          <div className="text-center mt-4">
                            <input
                              type="text"
                              value={selectedEmployee.state}
                              onChange={(e) =>
                                handleInputChange("state", e.target.value)
                              }
                              placeholder="State"
                              className="border rounded h-10 w-48 p-2 mx-1"
                            />
                          </div>
                        ) : (
                          <p className="text-center text-gray-500">
                            {selectedEmployee.state
                              ? selectedEmployee.state
                              : "N/A"}
                          </p>
                        ))}
                      {userRole !== "supervisor" &&
                        userRole !== "manager" &&
                        (isEditing ? (
                          <div className="text-center mt-4">
                            <input
                              type="text"
                              value={selectedEmployee.zip}
                              onChange={(e) =>
                                handleInputChange("zip", e.target.value)
                              }
                              placeholder="Zip"
                              className="border rounded h-10 w-48 p-2 mx-1"
                            />
                          </div>
                        ) : (
                          <p className="text-center text-gray-500">
                            {selectedEmployee.zip
                              ? selectedEmployee.zip
                              : "N/A"}
                          </p>
                        ))}

                      <p className="text-center text-gray-500">
                        {selectedEmployee.role}
                      </p>
                    </div>
                    <div className="md:w-2/3 px-4">
                      <div className="flex flex-wrap -mx-2 mb-4">
                        <div className="w-1/2 px-2 mb-4">
                          <h3 className="font-bold">Employment Information</h3>
                          {renderApprovedBySection()}
                          {renderApprovedNonCompete()}
                          {renderRoleSection()}
                          <div>
                            <h3>Work Location</h3>
                            {isEditing ? (
                              <Select
                                value={locations.find(
                                  (location) =>
                                    location.label ===
                                    selectedEmployee.workLocation
                                )}
                                onChange={handleLocationChange}
                                options={locations}
                                className="my-2"
                              />
                            ) : (
                              <p>{selectedEmployee.workLocation || "N/A"}</p>
                            )}
                          </div>
                          <div>
                            <h3>Reports To</h3>
                            {isEditing ? (
                              <Select
                                value={activeSupervisors.filter((supervisor) =>
                                  selectedEmployee.reportsTo?.includes(
                                    supervisor.value
                                  )
                                )}
                                onChange={(selectedOptions) =>
                                  handleInputChange(
                                    "reportsTo",
                                    selectedOptions.map(
                                      (option) => option.value
                                    )
                                  )
                                }
                                options={activeSupervisors}
                                isMulti
                                className="my-2"
                              />
                            ) : (
                              <p>
                                {selectedEmployee.reportsTo
                                  ? displayedSupervisorNames
                                  : "N/A"}
                              </p>
                            )}
                          </div>
                          <div>
                            <p>Position:</p>
                            {isEditing ? (
                              <Select
                                isMulti
                                value={
                                  Array.isArray(selectedEmployee.position)
                                    ? selectedEmployee.position.map((pos) => ({
                                        value: pos,
                                        label: pos,
                                      }))
                                    : selectedEmployee.position
                                    ? [
                                        {
                                          value: selectedEmployee.position,
                                          label: selectedEmployee.position,
                                        },
                                      ]
                                    : []
                                }
                                onChange={(selectedOptions) =>
                                  handleInputChange(
                                    "position",
                                    selectedOptions
                                      .map((option) => option.value)
                                      .join(", ")
                                  )
                                }
                                options={positions}
                                className="my-2"
                              />
                            ) : (
                              <p>
                                {Array.isArray(selectedEmployee.position)
                                  ? selectedEmployee.position.join(", ")
                                  : selectedEmployee.position || "N/A"}
                              </p>
                            )}
                          </div>
                          {renderHiringDateSection()}
                          {renderTerminationDateSection()}
                          {renderStatusSection()}
                          {renderPayMethod()}
                          {renderRateSection()}
                          {renderSalarySection()}
                        </div>
                        <div className="w-1/2 px-2 mb-4">
                          {userRole !== "manager" && (
                            <h3 className="font-bold">
                              Identification, Tax, and Personal Information
                            </h3>
                          )}
                          {userRole !== "manager" &&
                            (isEditing ? (
                              <div className="flex items-center">
                                <input
                                  type={isSSNVisible ? "text" : "password"}
                                  value={selectedEmployee.socialSecurity || ""}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const formattedValue = value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    handleInputChange(
                                      "socialSecurity",
                                      formattedValue
                                    );
                                  }}
                                  placeholder="XXX-XX-XXXX"
                                  autoComplete="off"
                                />
                                <FontAwesomeIcon
                                  icon={isSSNVisible ? faEyeSlash : faEye}
                                  onClick={toggleSSNVisibility}
                                  className="ml-2 cursor-pointer"
                                />
                              </div>
                            ) : (
                              <p>
                                Social Security:{" "}
                                {selectedEmployee.socialSecurity
                                  ? `***-**-${selectedEmployee.socialSecurity.substr(
                                      -4
                                    )}`
                                  : "N/A"}
                              </p>
                            ))}
                          {userRole !== "manager" &&
                            (isEditing ? (
                              <div className="flex items-center">
                                <input
                                  type={isITINVisible ? "text" : "password"}
                                  value={selectedEmployee.itin || ""}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const formattedValue = value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    handleInputChange("itin", formattedValue);
                                  }}
                                  placeholder="XXX-XX-XXXX"
                                  autoComplete="off"
                                />
                                <FontAwesomeIcon
                                  icon={isITINVisible ? faEyeSlash : faEye}
                                  onClick={toggleITINVisibility}
                                  className="ml-2 cursor-pointer"
                                />
                              </div>
                            ) : (
                              <p>
                                ITIN:{" "}
                                {selectedEmployee.itin
                                  ? `***-**-${selectedEmployee.itin.substr(-4)}`
                                  : "N/A"}
                              </p>
                            ))}
                          {userRole !== "manager" &&
                            (isEditing ? (
                              <div className="flex items-center">
                                <input
                                  type={isEINVisible ? "text" : "password"}
                                  value={selectedEmployee.ein || ""}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const formattedValue = value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    handleInputChange("ein", formattedValue);
                                  }}
                                  placeholder="XX-XXXXXXX"
                                  autoComplete="off"
                                />
                                <FontAwesomeIcon
                                  icon={isEINVisible ? faEyeSlash : faEye}
                                  onClick={toggleEINVisibility}
                                  className="ml-2 cursor-pointer"
                                />
                              </div>
                            ) : (
                              <p>
                                EIN:{" "}
                                {selectedEmployee.ein
                                  ? `${selectedEmployee.ein.substr(
                                      0,
                                      2
                                    )}-*****${selectedEmployee.ein.substr(-3)}`
                                  : "N/A"}
                              </p>
                            ))}
                          {userRole !== "manager" &&
                            (isEditing ? (
                              <div className="flex items-center">
                                <input
                                  type={
                                    isDriversLicenseVisible
                                      ? "text"
                                      : "password"
                                  }
                                  value={selectedEmployee.driversLicense || ""}
                                  onChange={(e) =>
                                    handleInputChange(
                                      "driversLicense",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter Driver's License"
                                  autoComplete="off"
                                />
                                <FontAwesomeIcon
                                  icon={
                                    isDriversLicenseVisible ? faEyeSlash : faEye
                                  }
                                  onClick={toggleDriversLicenseVisibility}
                                  className="ml-2 cursor-pointer"
                                />
                              </div>
                            ) : (
                              <p>
                                Driver's License:{" "}
                                {selectedEmployee.driversLicense
                                  ? `***-**-${selectedEmployee.driversLicense.substr(
                                      -4
                                    )}`
                                  : "N/A"}
                              </p>
                            ))}
                          <h3 className="font-bold mt-4">
                            Personal Information
                          </h3>
                          {renderBirthDateSection()}
                          {isEditing ? (
                            <div className="flex items-center my-2">
                              <label className="mr-2 w-1/4">Phone:</label>
                              <input
                                type="text"
                                value={
                                  formatPhoneNumber(selectedEmployee.phone) ||
                                  ""
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    "phone",
                                    e.target.value.replace(/\D/g, "")
                                  )
                                }
                                placeholder="(XXX) XXX-XXXX"
                                className="border rounded h-9 w-80 p-1.5 outline-none"
                                autoComplete="off"
                              />
                            </div>
                          ) : (
                            <p>
                              Phone Number:{" "}
                              {selectedEmployee.phone
                                ? formatPhoneNumber(selectedEmployee.phone)
                                : "N/A"}
                            </p>
                          )}
                          {userRole !== "supervisor" &&
                            userRole !== "manager" &&
                            (isEditing ? (
                              <div className="flex items-center my-2">
                                <label className="mr-2 w-1/4">Bank:</label>
                                <input
                                  type="text"
                                  value={selectedEmployee.bank || ""}
                                  onChange={(e) =>
                                    handleInputChange("bank", e.target.value)
                                  }
                                  placeholder="Bank name"
                                  className="border rounded h-9 w-80 p-1.5 outline-none"
                                  autoComplete="off"
                                />
                              </div>
                            ) : (
                              <p>
                                Bank:{" "}
                                {selectedEmployee.bank
                                  ? selectedEmployee.bank
                                  : "N/A"}
                              </p>
                            ))}
                          {userRole !== "supervisor" &&
                            userRole !== "manager" &&
                            (isEditing ? (
                              <div className="flex items-center my-2">
                                <label className="mr-2 w-1/4">Account:</label>
                                <input
                                  type="text"
                                  value={selectedEmployee.account || ""}
                                  onChange={(e) =>
                                    handleInputChange("account", e.target.value)
                                  }
                                  placeholder="Account number"
                                  className="border rounded h-9 w-80 p-1.5 outline-none"
                                  autoComplete="off"
                                />
                              </div>
                            ) : (
                              <p>
                                Account:{" "}
                                {selectedEmployee.account
                                  ? selectedEmployee.account
                                  : "N/A"}
                              </p>
                            ))}
                          {userRole !== "supervisor" &&
                            userRole !== "manager" &&
                            (isEditing ? (
                              <div className="flex items-center my-2">
                                <label className="mr-2 w-1/4">Routing:</label>
                                <input
                                  type="text"
                                  value={selectedEmployee.routing || ""}
                                  onChange={(e) =>
                                    handleInputChange("routing", e.target.value)
                                  }
                                  placeholder="Routing number"
                                  className="border rounded h-9 w-80 p-1.5 outline-none"
                                  autoComplete="off"
                                />
                              </div>
                            ) : (
                              <p>
                                Routing:{" "}
                                {selectedEmployee.routing
                                  ? selectedEmployee.routing
                                  : "N/A"}
                              </p>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex -mx-2">
                  <div className="w-1/3 px-2 mb-4">
                    <h3 className="font-bold">Career Evolution</h3>
                    {isEditing ? (
                      <textarea
                        className="resize-none w-full p-2 rounded"
                        style={{ boxSizing: "border-box" }}
                        rows="4"
                        placeholder="Provide a detailed account of the employee's career progression, including promotions, role changes, and significant achievements..."
                        value={selectedEmployee.careerEvolution || ""}
                        onChange={(e) =>
                          handleInputChange("careerEvolution", e.target.value)
                        }
                      />
                    ) : (
                      <p>{selectedEmployee.careerEvolution}</p>
                    )}
                  </div>
                  <div className="w-1/3 px-2 mb-4">
                    <h3 className="font-bold">Special Abilities</h3>
                    {isEditing ? (
                      <textarea
                        className="resize-none w-full p-2 rounded"
                        style={{ boxSizing: "border-box" }}
                        rows="4"
                        placeholder="Detail any special skills, talents, or certifications that set this employee apart from others..."
                        value={selectedEmployee.specialAbilities || ""}
                        onChange={(e) =>
                          handleInputChange("specialAbilities", e.target.value)
                        }
                      />
                    ) : (
                      <p>{selectedEmployee.specialAbilities}</p>
                    )}
                  </div>
                  <div className="w-1/3 px-2 mb-4">
                    <h3 className="font-bold">Termination Description</h3>
                    {isEditing ? (
                      <textarea
                        className="resize-none w-full p-2 rounded"
                        style={{ boxSizing: "border-box" }}
                        rows="4"
                        placeholder="Enter detailed information about the termination, including reasons and circumstances..."
                        value={selectedEmployee.terminationDescription || ""}
                        onChange={(e) =>
                          handleInputChange(
                            "terminationDescription",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      <p>{selectedEmployee.terminationDescription}</p>
                    )}
                  </div>
                  <div className="w-1/3 px-2 mb-4">
                    <h3 className="font-bold">Notes</h3>
                    {isEditing ? (
                      <textarea
                        className="resize-none w-full p-2 rounded"
                        style={{ boxSizing: "border-box" }}
                        rows="4"
                        placeholder="Enter any additional notes or observations about the user..."
                        value={selectedEmployee.notes || ""}
                        onChange={(e) =>
                          handleInputChange("notes", e.target.value)
                        }
                      />
                    ) : (
                      <p>{selectedEmployee.notes}</p>
                    )}
                  </div>
                </div>
              </div>
            )}
            {activeTab === "reportingUsers" && (
              <div>
                {employeeId ? (
                  <ReportingUsers employeeId={employeeId} />
                ) : (
                  <p>No employee selected.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeModal;
