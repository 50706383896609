import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import { auth, db, storage } from "./firebase";
import {
  collection,
  getDocs,
  updateDoc,
  Timestamp,
  onSnapshot,
  addDoc,
  arrayUnion,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faClock,
  faFileAlt,
  faExclamationTriangle,
  faTimes,
  faUser,
  faPlus,
  faBug,
  faLightbulb,
  faFilePdf,
  faThumbsUp,
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const fetchTickets = async () => {
  const ticketsCollection = collection(db, "tickets");
  const ticketsSnapshot = await getDocs(ticketsCollection);
  return ticketsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

const subscribeToTickets = (queryClient) => {
  const ticketsCollection = collection(db, "tickets");

  return onSnapshot(ticketsCollection, (snapshot) => {
    const tickets = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // Update the query cache
    queryClient.setQueryData(["tickets"], tickets);
  });
};

// Add this function after the existing fetchTickets function
const generateTicketId = (index) => {
  return `TICK-${String(index + 1).padStart(4, '0')}`;
};

function TicketDetails({ ticket, onClose, userName, isAdmin }) {
  const [expandedImage, setExpandedImage] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [status, setStatus] = useState(ticket?.status || "open");
  const [currentTicket, setCurrentTicket] = useState(ticket);
  const [currentType, setCurrentType] = useState(ticket?.type || "bug");
  const [currentPriority, setCurrentPriority] = useState(
    ticket?.priority || "low"
  );
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    // Set up real-time listener for ticket updates
    const unsubscribe = onSnapshot(
      doc(db, "tickets", ticket.id),
      (docSnapshot) => {
        const updatedData = docSnapshot.data();
        setStatus(updatedData.status || "open");
        setCurrentTicket({ id: docSnapshot.id, ...updatedData });
      }
    );

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [ticket.id]);

  // Updated helper function to correctly identify file types
  const isImageFile = (url) => {
    try {
      // For Firebase Storage URLs, extract the file name from the full URL
      const fileNameMatch = url.match(/\/([^/?#]+)[^/]*$/);
      const fileName = fileNameMatch ? fileNameMatch[1] : "";

      // Check for image extensions
      const hasImageExtension = /\.(jpg|jpeg|png|gif|webp)$/i.test(fileName);

      return hasImageExtension;
    } catch (error) {
      console.error("Error parsing URL:", error);
      return false;
    }
  };

  // Add this helper function to identify PDFs
  const isPdfFile = (url) => {
    try {
      const fileNameMatch = url.match(/\/([^/?#]+)[^/]*$/);
      const fileName = fileNameMatch ? fileNameMatch[1] : "";
      return /\.pdf$/i.test(fileName);
    } catch (error) {
      console.error("Error parsing URL:", error);
      return false;
    }
  };

  if (!ticket) {
    return null;
  }

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    return {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
    };
  };

  const { date, time } = formatDateTime(ticket.timeOfSubmission);

  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    setIsAddingComment(true);
    try {
      const ticketRef = doc(db, "tickets", ticket.id);
      const comment = {
        text: newComment,
        createdBy: userName,
        createdAt: Timestamp.now(),
        userUid: auth.currentUser?.uid || localStorage.getItem("userUid"),
      };

      // If comments array doesn't exist yet, initialize it
      if (!currentTicket.comments) {
        await updateDoc(ticketRef, {
          comments: [comment],
          lastUpdatedBy: userName,
          lastUpdatedAt: Timestamp.now(),
        });
      } else {
        await updateDoc(ticketRef, {
          comments: arrayUnion(comment),
          lastUpdatedBy: userName,
          lastUpdatedAt: Timestamp.now(),
        });
      }

      setNewComment("");
    } catch (error) {
      console.error("Error adding comment:", error);
    } finally {
      setIsAddingComment(false);
    }
  };

  // Add this console.log to debug
  console.log("Current ticket:", currentTicket);

  const handleFieldUpdate = async (field, value) => {
    try {
      const ticketRef = doc(db, "tickets", ticket.id);
      await updateDoc(ticketRef, {
        [field]: value,
        lastUpdatedBy: userName,
        lastUpdatedAt: Timestamp.now(),
      });
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    }
  };

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const uploadPromises = files.map(async (file) => {
        // Create a storage reference
        const storageRef = ref(
          storage,
          `tickets/${ticket.id}/${Date.now()}-${file.name}`
        );

        // Upload file with progress monitoring
        const uploadTask = uploadBytes(storageRef, file);

        // Wait for upload to complete
        await uploadTask;

        // Get download URL
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
      });

      const uploadedUrls = await Promise.all(uploadPromises);

      // Update ticket with new attachments
      const ticketRef = doc(db, "tickets", ticket.id);
      await updateDoc(ticketRef, {
        attachments: arrayUnion(...uploadedUrls),
        lastUpdatedBy: userName,
        lastUpdatedAt: Timestamp.now(),
      });

      // Add a system comment about the attachments
      const comment = {
        text: `Added ${files.length} attachment${files.length > 1 ? "s" : ""}`,
        createdBy: userName,
        createdAt: Timestamp.now(),
        isSystemComment: true,
      };

      await updateDoc(ticketRef, {
        comments: arrayUnion(comment),
      });
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleRemoveAttachment = async (urlToRemove) => {
    try {
      // Create a reference to the file to delete
      const fileRef = ref(storage, urlToRemove);
      await deleteObject(fileRef);

      // Update the ticket document to remove the URL
      const ticketRef = doc(db, "tickets", ticket.id);
      const updatedAttachments = currentTicket.attachments.filter(
        (url) => url !== urlToRemove
      );

      await updateDoc(ticketRef, {
        attachments: updatedAttachments,
        lastUpdatedBy: userName,
        lastUpdatedAt: Timestamp.now(),
      });

      // Add a system comment about removing the attachment
      const comment = {
        text: "Removed an attachment",
        createdBy: userName,
        createdAt: Timestamp.now(),
        isSystemComment: true,
      };

      await updateDoc(ticketRef, {
        comments: arrayUnion(comment),
      });
    } catch (error) {
      console.error("Error removing attachment:", error);
    }
  };

  // Add this new function
  const handleCloseTicket = async () => {
    try {
      const ticketRef = doc(db, "tickets", ticket.id);
      await updateDoc(ticketRef, {
        status: "closed",
        lastUpdatedBy: userName,
        lastUpdatedAt: Timestamp.now(),
      });

      // Add a system comment about closing the ticket
      const comment = {
        text: "Ticket closed by user",
        createdBy: userName,
        createdAt: Timestamp.now(),
        isSystemComment: true,
      };

      await updateDoc(ticketRef, {
        comments: arrayUnion(comment),
      });
    } catch (error) {
      console.error("Error closing ticket:", error);
    }
  };

  // Add this helper function to check if the current user created the ticket
  const isTicketCreator = () => {
    const currentUserUid =
      auth.currentUser?.uid || localStorage.getItem("userUid");
    return ticket.userUid === currentUserUid;
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      onClick={onClose}
    >
      <div
        className="relative top-20 mx-auto p-8 border w-11/12 md:w-3/4 lg:w-2/3 shadow-lg rounded-lg bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-3xl font-bold text-gray-900 flex items-center">
              <FontAwesomeIcon
                icon={faFileAlt}
                className="text-blue-500 mr-3"
              />
              {ticket.title || "Untitled Ticket"}
            </h2>
            <p className="text-sm text-gray-500 mt-1">Ticket Details</p>
          </div>
          <div className="relative">
            <input
              type="file"
              multiple
              onChange={handleFileUpload}
              className="hidden"
              id="file-upload"
              accept="image/*,.pdf"
            />
            <label
              htmlFor="file-upload"
              className={`cursor-pointer inline-flex items-center px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg
                ${isUploading ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Files
            </label>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              General Information
            </h3>
            <InfoItem icon={faCalendarAlt} label="Date" value={date} />
            <InfoItem icon={faClock} label="Time" value={time} />
            <InfoItem
              icon={faUser}
              label="Created By"
              value={ticket.createdBy}
            />

            {isAdmin ? (
              <>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Type:
                  </label>
                  <select
                    value={currentType}
                    onChange={(e) => {
                      setCurrentType(e.target.value);
                      handleFieldUpdate("type", e.target.value);
                    }}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700"
                  >
                    <option value="bug">Bug Report</option>
                    <option value="feature">Feature Request</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Priority:
                  </label>
                  <select
                    value={currentPriority}
                    onChange={(e) => {
                      setCurrentPriority(e.target.value);
                      handleFieldUpdate("priority", e.target.value);
                    }}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700"
                  >
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Status:
                  </label>
                  <select
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      handleFieldUpdate("status", e.target.value);
                    }}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700"
                  >
                    <option value="open">Open</option>
                    <option value="in-progress">In Progress</option>
                    <option value="resolved">Resolved</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
              </>
            ) : (
              <>
                <InfoItem icon={faFileAlt} label="Type" value={currentType} />
                <InfoItem
                  icon={faExclamationTriangle}
                  label="Priority"
                  value={currentPriority}
                />
                <div className="mt-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Status:
                  </label>
                  <div className="flex items-center space-x-4">
                    <div className="px-3 py-2 bg-gray-100 rounded text-gray-700">
                      {status.charAt(0).toUpperCase() + status.slice(1)}
                    </div>
                    {status === "resolved" && isTicketCreator() && (
                      <button
                        onClick={handleCloseTicket}
                        className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-md flex items-center space-x-2"
                      >
                        <FontAwesomeIcon icon={faThumbsUp} />
                        <span>Close Ticket</span>
                      </button>
                    )}
                  </div>
                  {status === "resolved" && isTicketCreator() && (
                    <p className="text-sm text-gray-600 mt-2">
                      If the issue has been resolved to your satisfaction, you
                      can close this ticket.
                    </p>
                  )}
                  {status === "resolved" && !isTicketCreator() && (
                    <p className="text-sm text-gray-600 mt-2">
                      Only the ticket creator can close this ticket.
                    </p>
                  )}
                </div>
              </>
            )}

            {/* Show last updated info if it exists */}
            {ticket.lastUpdatedBy && (
              <div className="mt-4 text-sm text-gray-600">
                <p>Last updated by: {ticket.lastUpdatedBy}</p>
                <p>
                  {ticket.lastUpdatedAt?.toDate().toLocaleString() ||
                    "Unknown time"}
                </p>
              </div>
            )}
          </div>

          <div className="bg-gray-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Description
            </h3>
            <p className="text-gray-800 whitespace-pre-wrap">
              {ticket.description || "No description provided"}
            </p>
          </div>

          {/* Attachments Section */}
          <div className="col-span-2 bg-gray-50 p-6 rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-800">
                Attachments
              </h3>
              <div className="relative">
                <input
                  type="file"
                  multiple
                  onChange={handleFileUpload}
                  className="hidden"
                  id="file-upload"
                  accept="image/*,.pdf"
                />
                <label
                  htmlFor="file-upload"
                  className={`cursor-pointer inline-flex items-center px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg
                    ${isUploading ? "opacity-50 cursor-not-allowed" : ""}`}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Add Files
                </label>
              </div>
            </div>

            {isUploading && (
              <div className="mb-4">
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
                <p className="text-sm text-gray-600 mt-1">Uploading...</p>
              </div>
            )}

            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {currentTicket.attachments?.map((url, index) => {
                const isImage = isImageFile(url);
                const isPdf = isPdfFile(url);

                return (
                  <div key={index} className="relative group">
                    {isImage ? (
                      <img
                        src={url}
                        alt={`Attachment ${index + 1}`}
                        className="w-full h-32 object-cover rounded-md cursor-pointer hover:opacity-80 transition-opacity shadow"
                        onClick={() => setExpandedImage(url)}
                      />
                    ) : (
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center w-full h-32 bg-gray-100 rounded-md cursor-pointer hover:bg-gray-200 transition-colors shadow"
                      >
                        <div className="text-center">
                          <FontAwesomeIcon
                            icon={isPdf ? faFilePdf : faFileAlt}
                            className={`text-4xl mb-2 ${
                              isPdf ? "text-red-600" : "text-gray-600"
                            }`}
                          />
                          <p className="text-sm text-gray-600">
                            {isPdf ? "View PDF" : "View File"}
                          </p>
                        </div>
                      </a>
                    )}

                    {isAdmin ||
                    ticket.userUid ===
                      (auth.currentUser?.uid ||
                        localStorage.getItem("userUid")) ? (
                      <button
                        onClick={() => handleRemoveAttachment(url)}
                        className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <FontAwesomeIcon icon={faTimes} className="w-4 h-4" />
                      </button>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-span-2 bg-gray-50 p-6 rounded-lg shadow mt-4">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Comments & Updates
            </h3>

            <div className="space-y-4 mb-4 max-h-60 overflow-y-auto pr-2 relative">
              {/* Add visual indicator for scrolling */}
              <div className="absolute right-0 top-0 bottom-0 w-2 bg-gray-200 rounded">
                <div className="w-full h-full bg-gray-400 rounded opacity-50 hover:opacity-75 transition-opacity" />
              </div>

              {currentTicket.comments?.length === 0 ? (
                <p className="text-gray-500 text-center italic">
                  No comments yet
                </p>
              ) : (
                currentTicket.comments?.map((comment, index) => (
                  <div
                    key={index}
                    className={`bg-white p-4 rounded-lg shadow ${
                      comment.userUid === "LmnRby2FDeaZmk7dhEZYBpHQoGA2"
                        ? "border-l-4 border-green-500 bg-green-50"
                        : comment.createdBy === userName
                        ? "border-l-4 border-blue-500"
                        : ""
                    }`}
                  >
                    <div className="flex justify-between items-start">
                      <div className="flex items-center">
                        <div
                          className={`w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold
                          ${
                            comment.userUid === "LmnRby2FDeaZmk7dhEZYBpHQoGA2"
                              ? "bg-green-500"
                              : comment.createdBy === userName
                              ? "bg-blue-500"
                              : "bg-gray-500"
                          }`}
                        >
                          {comment.createdBy?.charAt(0)?.toUpperCase() || "?"}
                        </div>
                        <div className="ml-2">
                          <span className="font-medium">
                            {comment.createdBy}
                            {comment.userUid ===
                              "LmnRby2FDeaZmk7dhEZYBpHQoGA2" && (
                              <span className="ml-2 text-xs bg-green-100 text-green-800 px-2 py-1 rounded">
                                IT Support
                              </span>
                            )}
                          </span>
                          <span className="text-xs text-gray-500 block">
                            {comment.createdAt?.toDate().toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>
                    <p className="mt-2 text-gray-700 whitespace-pre-wrap">
                      {comment.text}
                    </p>
                  </div>
                ))
              )}
            </div>

            {/* Add subtle text indicator */}
            {currentTicket.comments?.length > 3 && (
              <p className="text-sm text-gray-500 text-center mb-4">
                Scroll to see more comments
              </p>
            )}

            {/* Comment input section */}
            <div className="mt-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Add a Comment
              </label>
              <textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Add your comment or additional information..."
                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                rows="3"
              />
              <div className="mt-2 flex justify-between items-center">
                <span className="text-sm text-gray-500">
                  Commenting as {userName}
                </span>
                <button
                  onClick={handleAddComment}
                  disabled={isAddingComment || !newComment.trim()}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
                >
                  {isAddingComment ? "Adding..." : "Add Comment"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 text-center">
          <button
            className="px-6 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-colors"
            onClick={onClose}
          >
            Close
          </button>
        </div>

        {expandedImage && isImageFile(expandedImage) && (
          <div
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-50"
            onClick={() => setExpandedImage(null)}
          >
            <img
              src={expandedImage}
              alt="Expanded view"
              className="max-w-full max-h-full object-contain"
            />
            <button
              className="absolute top-4 right-4 text-white text-2xl"
              onClick={() => setExpandedImage(null)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

// Updated InfoItem component
function InfoItem({ icon, label, value }) {
  return (
    <div className="mb-3 flex items-center">
      <FontAwesomeIcon icon={icon} className="text-gray-500 mr-2 w-5" />
      <span className="font-medium text-gray-600">{label}:</span>
      <span className="text-gray-800 ml-2">{value}</span>
    </div>
  );
}

function TicketSummarySkeleton() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
      {[...Array(5)].map((_, i) => (
        <div key={i} className="rounded-lg p-6 shadow-lg">
          <Skeleton height={24} width={100} className="mb-2" />
          <Skeleton height={36} width={60} />
        </div>
      ))}
    </div>
  );
}

function TableLoadingSkeleton() {
  return (
    <div className="min-w-full">
      <div className="mb-4">
        {[...Array(8)].map((_, i) => (
          <Skeleton key={i} height={40} className="mb-2" />
        ))}
      </div>
      {[...Array(5)].map((_, rowIndex) => (
        <div key={rowIndex} className="flex space-x-4 mb-4">
          {[...Array(8)].map((_, colIndex) => (
            <Skeleton key={colIndex} width={colIndex === 6 ? 200 : 100} height={30} />
          ))}
        </div>
      ))}
    </div>
  );
}

// Add filterStatus to the props
function TicketSummary({ tickets, filterStatus, onFilterChange }) {
  const summary = useMemo(() => {
    if (!tickets) return { total: 0, open: 0, inProgress: 0, resolved: 0, closed: 0 };

    return tickets.reduce(
      (acc, ticket) => {
        acc.total++;
        switch (ticket.status) {
          case "open":
            acc.open++;
            break;
          case "in-progress":
            acc.inProgress++;
            break;
          case "resolved":
            acc.resolved++;
            break;
          case "closed":
            acc.closed++;
            break;
          default:
            acc.open++;
        }
        return acc;
      },
      { total: 0, open: 0, inProgress: 0, resolved: 0, closed: 0 }
    );
  }, [tickets]);

  // Helper function to determine button styles
  const getButtonStyles = (status) => {
    const baseStyles = "rounded-lg p-6 shadow-lg transform hover:scale-105 transition-transform duration-200 cursor-pointer";
    const isActive = filterStatus === status;
    
    if (status === 'total') {
      return `${baseStyles} ${isActive ? 'bg-gray-600' : 'bg-gray-700'} hover:bg-gray-600`;
    }
    
    const statusStyles = {
      open: isActive ? 'bg-blue-700' : 'bg-blue-600',
      'in-progress': isActive ? 'bg-yellow-700' : 'bg-yellow-600',
      resolved: isActive ? 'bg-green-700' : 'bg-green-600',
      closed: isActive ? 'bg-purple-700' : 'bg-purple-600'
    };

    return `${baseStyles} ${statusStyles[status] || 'bg-gray-700'}`;
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
      <div
        onClick={() => onFilterChange(null)}
        className={getButtonStyles('total')}
      >
        <div className="text-gray-300 font-medium mb-2">Total Tickets</div>
        <div className="text-3xl font-bold text-white">{summary.total}</div>
      </div>
      <div
        onClick={() => onFilterChange("open")}
        className={getButtonStyles('open')}
      >
        <div className="text-blue-100 font-medium mb-2">Open</div>
        <div className="text-3xl font-bold text-white">{summary.open}</div>
      </div>
      <div
        onClick={() => onFilterChange("in-progress")}
        className={getButtonStyles('in-progress')}
      >
        <div className="text-yellow-100 font-medium mb-2">In Progress</div>
        <div className="text-3xl font-bold text-white">{summary.inProgress}</div>
      </div>
      <div
        onClick={() => onFilterChange("resolved")}
        className={getButtonStyles('resolved')}
      >
        <div className="text-green-100 font-medium mb-2">Resolved</div>
        <div className="text-3xl font-bold text-white">{summary.resolved}</div>
      </div>
      <div
        onClick={() => onFilterChange("closed")}
        className={getButtonStyles('closed')}
      >
        <div className="text-purple-100 font-medium mb-2">Closed</div>
        <div className="text-3xl font-bold text-white">{summary.closed}</div>
      </div>
    </div>
  );
}

function CreateTicketModal({ isOpen, onClose, userName }) {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    priority: "low",
    type: "bug",
    attachments: [],
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Cleanup function
  const cleanupAndClose = async () => {
    try {
      // Delete uploaded files from storage
      const deletePromises = uploadedFiles.map(async (file) => {
        if (file.storageRef) {
          await deleteObject(file.storageRef);
        }
      });
      await Promise.all(deletePromises);
    } catch (error) {
      console.error("Error deleting files:", error);
    }

    setFormData({
      title: "",
      description: "",
      priority: "low",
      type: "bug",
      attachments: [],
    });
    setUploadedFiles([]);
    setShowConfirmDialog(false);
    onClose();
  };

  // Remove file
  const handleRemoveFile = async (index) => {
    const file = uploadedFiles[index];
    try {
      if (file.storageRef) {
        await deleteObject(file.storageRef);
      }
      setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
      setFormData((prev) => ({
        ...prev,
        attachments: prev.attachments.filter((_, i) => i !== index),
      }));
    } catch (error) {
      console.error("Error removing file:", error);
    }
  };

  // Handle modal close with confirmation
  const handleCloseModal = () => {
    if (formData.description || uploadedFiles.length > 0) {
      setShowConfirmDialog(true);
    } else {
      cleanupAndClose();
    }
  };

  // Handle file drop
  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    await handleFiles(files);
  };

  // Handle file input change
  const handleFileInput = async (e) => {
    const files = Array.from(e.target.files);
    await handleFiles(files);
  };

  // Process files
  const handleFiles = async (files) => {
    setIsUploading(true);
    const validFiles = files.filter(
      (file) =>
        file.type.startsWith("image/") || file.type === "application/pdf"
    );

    try {
      const uploadPromises = validFiles.map(async (file) => {
        const storageRef = ref(
          storage,
          `temp-uploads/${Date.now()}-${file.name}`
        );
        const snapshot = await uploadBytes(storageRef, file);
        const url = await getDownloadURL(snapshot.ref);

        return {
          name: file.name,
          url,
          storageRef,
          type: file.type,
        };
      });

      const uploadedFiles = await Promise.all(uploadPromises);
      setUploadedFiles((prev) => [...prev, ...uploadedFiles]);
      setFormData((prev) => ({
        ...prev,
        attachments: [
          ...(prev.attachments || []),
          ...uploadedFiles.map((f) => f.url),
        ],
      }));
    } catch (error) {
      console.error("Error uploading files:", error);
      // Add error handling UI here
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const ticketsCollection = collection(db, "tickets");
      const ticketsSnapshot = await getDocs(ticketsCollection);
      const nextIndex = ticketsSnapshot.size;
      
      await addDoc(ticketsCollection, {
        ...formData,
        ticketId: generateTicketId(nextIndex),
        status: "open",
        createdBy: userName,
        createdAt: Timestamp.now(),
        timeOfSubmission: Timestamp.now(),
        voteCount: 0,
        votes: {},
        userUid: auth.currentUser?.uid || localStorage.getItem("userUid"),
      });

      onClose();
    } catch (error) {
      console.error("Error creating ticket:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add this helper function at the top of the component
  const truncateFileName = (fileName, maxLength = 20) => {
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.split(".").pop();
    const nameWithoutExt = fileName.slice(0, -(extension.length + 1));
    const truncatedName = nameWithoutExt.slice(0, maxLength - 3) + "...";
    return `${truncatedName}.${extension}`;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      {showConfirmDialog ? (
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <h3 className="text-lg font-medium text-gray-900">
            Discard changes?
          </h3>
          <p className="mt-2 text-sm text-gray-500">
            All your changes will be lost. This action cannot be undone.
          </p>
          <div className="mt-4 flex justify-end space-x-3">
            <button
              onClick={() => setShowConfirmDialog(false)}
              className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={cleanupAndClose}
              className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md"
            >
              Discard
            </button>
          </div>
        </div>
      ) : (
        <div className="relative top-20 mx-auto p-8 border max-w-xl w-11/12 shadow-lg rounded-lg bg-white">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Create New Ticket</h2>
            <button
              onClick={handleCloseModal}
              className="text-gray-500 hover:text-gray-700"
              aria-label="Close modal"
            >
              <FontAwesomeIcon icon={faTimes} className="text-xl" />
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Title *
              </label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                className="shadow border rounded w-full py-2 px-3 text-gray-700"
                placeholder="Enter ticket title..."
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Type
              </label>
              <select
                value={formData.type}
                onChange={(e) =>
                  setFormData({ ...formData, type: e.target.value })
                }
                className="shadow border rounded w-full py-2 px-3 text-gray-700"
              >
                <option value="bug">Bug Report</option>
                <option value="feature">Feature Request</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Priority
              </label>
              <select
                value={formData.priority}
                onChange={(e) =>
                  setFormData({ ...formData, priority: e.target.value })
                }
                className="shadow border rounded w-full py-2 px-3 text-gray-700"
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Description
              </label>
              <textarea
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                className="shadow border rounded w-full py-2 px-3 text-gray-700 h-32"
                placeholder="Please describe the issue or feature request..."
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Attachments
              </label>
              <div
                className={`border-2 border-dashed rounded-lg p-4 text-center ${
                  isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"
                }`}
                onDragOver={(e) => {
                  e.preventDefault();
                  setIsDragging(true);
                }}
                onDragLeave={() => setIsDragging(false)}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  multiple
                  onChange={handleFileInput}
                  className="hidden"
                  id="file-input"
                  accept="image/*,.pdf"
                />
                <label
                  htmlFor="file-input"
                  className="cursor-pointer text-blue-600 hover:text-blue-800"
                >
                  Click to upload
                </label>
                <span className="text-gray-500"> or drag and drop</span>
                <p className="text-xs text-gray-500 mt-1">
                  Support for images and PDF files
                </p>
              </div>

              {/* File preview section */}
              {uploadedFiles.length > 0 && (
                <div className="mt-4 space-y-2">
                  {uploadedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between bg-gray-50 p-2 rounded"
                    >
                      <div className="flex items-center flex-1 min-w-0">
                        {file.type.startsWith("image/") ? (
                          <img
                            src={file.url}
                            alt=""
                            className="w-10 h-10 object-cover rounded flex-shrink-0"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faFileAlt}
                            className="w-10 h-10 text-gray-400 flex-shrink-0"
                          />
                        )}
                        <span
                          className="ml-2 text-sm truncate"
                          title={file.name}
                        >
                          {truncateFileName(file.name)}
                        </span>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                        className="text-red-500 hover:text-red-700 ml-2 flex-shrink-0"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={handleCloseModal}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting || isUploading}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                {isSubmitting ? "Creating..." : "Create Ticket"}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

function Tickets() {
  const [searchParams] = useSearchParams();
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [sorting, setSorting] = useState([
    {
      id: "status",
      desc: true,
    },
    {
      id: "Submission Date",
      desc: true,
    },
  ]);
  const [searchText, setSearchText] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [statusFilter, setStatusFilter] = useState(null);

  const queryClient = useQueryClient();

  const {
    data: tickets,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["tickets"],
    queryFn: () => {
      // Initial fetch
      return fetchTickets().then((initialData) => {
        // Set up real-time listener
        subscribeToTickets(queryClient);
        return initialData;
      });
    },
    // Disable automatic refetching since we're using real-time updates
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const [userName, setUserName] = useState(() => {
    const firstName = localStorage.getItem("userFirstName") || "";
    const lastName = localStorage.getItem("userLastName") || "";
    return `${firstName} ${lastName}`.trim();
  });

  useEffect(() => {
    const storedFirstName = localStorage.getItem("userFirstName");
    const storedLastName = localStorage.getItem("userLastName");
    if (storedFirstName || storedLastName) {
      setUserName(`${storedFirstName} ${storedLastName}`.trim());
    }
  }, []);

  useEffect(() => {
    const ticketId = searchParams.get("id");
    if (ticketId && tickets) {
      const ticket = tickets.find((tic) => tic.id === ticketId);
      if (ticket) {
        setSelectedTicket(ticket);
      }
    }
  }, [searchParams, tickets]);

  const handleViewDetails = (ticket) => {
    setSelectedTicket(ticket);
  };

  const filteredTickets = useMemo(() => {
    if (!tickets) return [];

    let filtered = tickets.filter((ticket) => {
      // First apply status filter if it exists
      if (statusFilter && ticket.status !== statusFilter) {
        return false;
      }

      // Then apply search filter
      if (searchText === "") return true;

      const searchLower = searchText.toLowerCase();
      return (
        ticket.ticketId?.toLowerCase().includes(searchLower) ||
        ticket.title?.toLowerCase().includes(searchLower) ||
        ticket.description?.toLowerCase().includes(searchLower) ||
        ticket.createdBy?.toLowerCase().includes(searchLower) ||
        ticket.type?.toLowerCase().includes(searchLower) ||
        ticket.status?.toLowerCase().includes(searchLower) ||
        ticket.priority?.toLowerCase().includes(searchLower)
      );
    });

    // Sort logic remains the same
    filtered.sort((a, b) => {
      if (a.status === "closed" && b.status !== "closed") return 1;
      if (a.status !== "closed" && b.status === "closed") return -1;

      const dateA = a.timeOfSubmission?.seconds || 0;
      const dateB = b.timeOfSubmission?.seconds || 0;
      return dateB - dateA;
    });

    return filtered;
  }, [tickets, searchText, statusFilter]);

  const toggleVote = useCallback(
    async (ticketId, uid) => {
      const ticketRef = doc(db, "tickets", ticketId);

      try {
        const ticketDoc = await getDoc(ticketRef);
        const currentVotes = ticketDoc.data().votes || {};

        if (currentVotes[uid]) {
          // Remove vote
          const { [uid]: removedVote, ...remainingVotes } = currentVotes;
          await updateDoc(ticketRef, {
            votes: remainingVotes,
            voteCount: (ticketDoc.data().voteCount || 0) - 1,
          });
        } else {
          // Add vote
          await updateDoc(ticketRef, {
            [`votes.${uid}`]: true,
            voteCount: (ticketDoc.data().voteCount || 0) + 1,
          });
        }
      } catch (error) {
        console.error("Error toggling vote:", error);
        throw error;
      }
    },
    [
      /* no dependencies needed */
    ]
  );

  const columns = useMemo(
    () => [
      {
        header: "Ticket ID",
        accessorKey: "ticketId",
        cell: ({ getValue }) => (
          <span className="font-mono text-sm font-medium text-gray-700">
            {getValue() || "N/A"}
          </span>
        ),
      },
      {
        header: "Votes",
        accessorFn: (row) => row.voteCount || 0,
        cell: ({ row, getValue }) => {
          const voteCount = getValue();
          const hasVoted = row.original.votes?.[auth.currentUser?.uid] || false;

          return (
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (!auth.currentUser) return; // Ensure user is logged in
                toggleVote(row.original.id, auth.currentUser.uid);
              }}
              className={`flex items-center space-x-2 px-3 py-1 rounded-full text-sm
                ${
                  hasVoted
                    ? "bg-blue-100 text-blue-800"
                    : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                }`}
            >
              <FontAwesomeIcon
                icon={faThumbsUp}
                className={hasVoted ? "text-blue-500" : "text-gray-500"}
              />
              <span className="font-semibold">{voteCount}</span>
            </button>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ getValue }) => {
          const status = getValue();
          const statusConfig = {
            open: "bg-blue-100 text-blue-800",
            "in-progress": "bg-yellow-100 text-yellow-800",
            resolved: "bg-green-100 text-green-800",
            closed: "bg-purple-100 text-purple-800",
          };
          const bgColorClass =
            statusConfig[status] || "bg-gray-100 text-gray-800";
          return (
            <span
              className={`px-2 py-1 rounded-full text-xs font-semibold uppercase ${bgColorClass}`}
            >
              {status}
            </span>
          );
        },
      },
      {
        header: "Type",
        accessorKey: "type",
        cell: ({ getValue }) => {
          const type = getValue();
          const config = {
            bug: {
              icon: faBug,
              bgColor: "bg-red-100",
              textColor: "text-red-800",
              label: "Bug",
            },
            feature: {
              icon: faLightbulb,
              bgColor: "bg-purple-100",
              textColor: "text-purple-800",
              label: "Feature",
            },
          }[type] || {
            icon: faLightbulb,
            bgColor: "bg-gray-100",
            textColor: "text-gray-800",
            label: type,
          };

          return (
            <span
              className={`px-3 py-1 rounded-full text-xs font-semibold uppercase ${config.bgColor} ${config.textColor} flex items-center gap-1 w-fit`}
            >
              <FontAwesomeIcon icon={config.icon} className="text-xs" />
              <span>{config.label}</span>
            </span>
          );
        },
      },
      {
        header: "Priority",
        accessorKey: "priority",
        cell: ({ getValue }) => {
          const priority = getValue();
          const bgColor =
            {
              high: "bg-red-100 text-red-800",
              medium: "bg-yellow-100 text-yellow-800",
              low: "bg-green-100 text-green-800",
            }[priority] || "bg-gray-100 text-gray-800";
          return (
            <span
              className={`px-3 py-1 rounded-full text-xs font-semibold uppercase ${bgColor}`}
            >
              {priority}
            </span>
          );
        },
      },
      {
        header: "Created By",
        accessorKey: "createdBy",
        cell: ({ getValue }) => (
          <div className="flex items-center">
            <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white font-semibold mr-2">
              {getValue()?.charAt(0)?.toUpperCase() || "?"}
            </div>
            <span className="font-medium text-gray-700">{getValue()}</span>
          </div>
        ),
      },
      {
        header: "Submission Date",
        accessorFn: (row) => {
          if (row.timeOfSubmission && row.timeOfSubmission.seconds) {
            return new Date(row.timeOfSubmission.seconds * 1000);
          }
          return null;
        },
        cell: ({ getValue }) => {
          const date = getValue();
          if (!date) return "N/A";

          const formattedDate = date.toLocaleDateString();
          const formattedTime = date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });

          return (
            <div className="flex flex-col">
              <span className="font-medium text-gray-700">{formattedDate}</span>
              <span className="text-xs text-gray-500">{formattedTime}</span>
            </div>
          );
        },
      },
      {
        header: "Title",
        accessorKey: "title",
        cell: ({ getValue }) => (
          <span className="font-medium text-gray-900">
            {getValue() || "Untitled Ticket"}
          </span>
        ),
      },
      {
        header: "Actions",
        cell: ({ row }) => (
          <button
            onClick={() => handleViewDetails(row.original)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
          >
            View Details
          </button>
        ),
      },
    ],
    [toggleVote]
  );

  const table = useReactTable({
    data: filteredTickets || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  const [isAdmin] = useState(() => {
    const currentUserUid =
      auth.currentUser?.uid || localStorage.getItem("userUid");
    console.log("Current User UID:", currentUserUid);
    return currentUserUid === "LmnRby2FDeaZmk7dhEZYBpHQoGA2";
  });

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="flex justify-between items-center">
            <Skeleton width={200} height={32} />
          </div>

          <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
            <div className="flex flex-col space-y-6">
              <TicketSummarySkeleton />
              
              <div className="flex flex-col gap-2">
                <Skeleton width={120} height={20} />
                <div className="flex gap-4">
                  <Skeleton height={48} className="flex-1" />
                  <Skeleton width={150} height={48} />
                </div>
              </div>
            </div>
          </div>

          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <TableLoadingSkeleton />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    console.log("Showing error state:", error);
    return (
      <div className="text-red-500 text-center">Error: {error.message}</div>
    );
  }

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <div className="py-8">
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-bold text-white uppercase tracking-wider">
            Support Tickets
          </h1>
        </div>

        <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
          <div className="flex flex-col space-y-6">
            <TicketSummary 
              tickets={tickets} 
              filterStatus={statusFilter}
              onFilterChange={setStatusFilter}
            />

            {/* Updated search section with inline button */}
            <div className="flex flex-col gap-2">
              <label className="text-sm font-semibold text-gray-300">
                Search Tickets
              </label>
              <div className="flex gap-4">
                {" "}
                {/* New container for input and button */}
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search tickets..."
                  className="flex-1 p-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                />
                <button
                  onClick={() => setIsCreateModalOpen(true)}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold px-6 rounded-lg flex items-center transition-colors duration-200 whitespace-nowrap"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Create Ticket
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <span>
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted()] ?? null}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className={`${
                      row.original.status === "closed"
                        ? "opacity-75 bg-gray-100"
                        : "bg-white"
                    } hover:bg-gray-100 transition-colors duration-150`}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="px-5 py-5 border-b border-gray-200 text-sm"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Update pagination controls */}
        <div className="px-5 py-5 bg-gray-800 border-t border-gray-700 flex flex-col xs:flex-row items-center xs:justify-between">
          <div className="inline-flex rounded-full shadow-sm" role="group">
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className={`inline-flex items-center px-4 py-2 text-sm font-medium text-blue-300 bg-blue-900 rounded-l-full hover:bg-blue-800 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:outline-none transition-colors duration-200 ${
                !table.getCanPreviousPage()
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              <FontAwesomeIcon icon={faLongArrowAltLeft} className="mr-2" />
              Previous
            </button>
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className={`inline-flex items-center px-4 py-2 text-sm font-medium text-blue-300 bg-blue-900 rounded-r-full hover:bg-blue-800 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:outline-none transition-colors duration-200 ${
                !table.getCanNextPage() ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Next
              <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-2" />
            </button>
          </div>
          <span className="text-sm text-gray-300 mt-2 xs:mt-0">
            Page{" "}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>{" "}
            | Total {filteredTickets.length} tickets
          </span>
        </div>
      </div>
      {selectedTicket && (
        <TicketDetails
          ticket={selectedTicket}
          onClose={() => setSelectedTicket(null)}
          userName={userName}
          isAdmin={isAdmin}
        />
      )}
      {isCreateModalOpen && (
        <CreateTicketModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          userName={userName}
        />
      )}
    </div>
  );
}

export default Tickets;
