import React, { useState, useEffect, useCallback, useMemo } from "react";
import { motion } from "framer-motion";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';

function Transactions() {
  const hours = useMemo(
    () => Array.from({ length: 20 }, (_, i) => (i + 5) % 24),
    []
  );
  const [returns, setReturns] = useState(Array(20).fill(""));
  const [reservations, setReservations] = useState(Array(20).fill(""));
  const [totalDrivers, setTotalDrivers] = useState(0);
  const [scheduleDetails, setScheduleDetails] = useState({
    schedule: [],
    activeDrivers: [],
    driversNeeded: [],
  });
  const [avgTransactionsPerDriver, setAvgTransactionsPerDriver] = useState(6);

  const handleReturnsChange = (index, value) => {
    if (/^\d*\.?\d*$/.test(value)) {
      const newReturns = [...returns];
      newReturns[index] = value;
      setReturns(newReturns);
    }
  };

  const handleReservationsChange = (index, value) => {
    if (/^\d*\.?\d*$/.test(value)) {
      const newReservations = [...reservations];
      newReservations[index] = value;
      setReservations(newReservations);
    }
  };

  const handleAvgTransactionsChange = (value) => {
    if (/^\d*\.?\d*$/.test(value)) {
      setAvgTransactionsPerDriver(value === "" ? "" : parseFloat(value));
    }
  };

  const calculateAverage = (index) => {
    const returnsNum = parseFloat(returns[index]);
    const reservationsNum = parseFloat(reservations[index]);

    if (!isNaN(returnsNum) && !isNaN(reservationsNum)) {
      return ((returnsNum + reservationsNum) / 2).toFixed(2);
    }
    return "0.00";
  };

  const calculateDriversNeeded = useCallback(
    (index) => {
      const returnsNum = parseFloat(returns[index]) || 0;
      const reservationsNum = parseFloat(reservations[index]) || 0;
      const averageTransactions = (returnsNum + reservationsNum) / 2;

      return (averageTransactions / (avgTransactionsPerDriver || 1)).toFixed(2);
    },
    [returns, reservations, avgTransactionsPerDriver]
  );

  const formatHourRange = (hour) => {
    const start = new Date(0, 0, 0, hour).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const end = new Date(0, 0, 0, (hour + 1) % 24).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${start} - ${end}`;
  };

  const calculateOptimizedSchedule = useCallback(() => {
    const driversNeeded = hours.map((hour) =>
      parseFloat(calculateDriversNeeded(hour))
    );
    let schedule = Array(20).fill(0);
    let activeDrivers = Array(20).fill(0);
    let totalDriversCount = 0;
    const shiftDuration = 8;

    const roundDrivers = (value) => {
      const fractionalPart = value % 1;
      return fractionalPart < 0.6 ? Math.floor(value) : Math.ceil(value);
    };

    for (let index = 0; index < 20; index++) {
      const currentDriversNeeded = driversNeeded[index];

      if (index >= shiftDuration) {
        activeDrivers[index] =
          activeDrivers[index - 1] - schedule[index - shiftDuration];
      } else {
        activeDrivers[index] = index > 0 ? activeDrivers[index - 1] : 0;
      }

      if (currentDriversNeeded > activeDrivers[index]) {
        const driversToAdd = roundDrivers(
          currentDriversNeeded - activeDrivers[index]
        );
        schedule[index] = driversToAdd;
        activeDrivers[index] += driversToAdd;
        totalDriversCount += driversToAdd;
      } else {
        schedule[index] = 0;
      }

      // Ensure active drivers don't exceed needed drivers
      activeDrivers[index] = Math.max(
        activeDrivers[index],
        roundDrivers(currentDriversNeeded)
      );

      console.log(
        `Hour ${hours[index]}: Needed=${currentDriversNeeded}, Active=${activeDrivers[index]}, Adding=${schedule[index]}`
      );
    }

    // Second pass: adjust for the 8-hour shift constraint
    for (let index = 20 - shiftDuration; index < 20; index++) {
      if (activeDrivers[index] < driversNeeded[index]) {
        const deficit = driversNeeded[index] - activeDrivers[index];
        for (let j = index - shiftDuration; j >= 0; j--) {
          if (schedule[j] > 0) {
            const availableDrivers = Math.min(schedule[j], deficit);
            schedule[j] -= availableDrivers;
            activeDrivers[j] += availableDrivers;
            activeDrivers[index] += availableDrivers;
            if (activeDrivers[index] >= driversNeeded[index]) break;
          }
        }
      }
    }

    // Third pass: ensure drivers are added earlier to cover later needs
    for (let index = 20 - shiftDuration; index < 20; index++) {
      if (activeDrivers[index] < driversNeeded[index]) {
        const deficit = driversNeeded[index] - activeDrivers[index];
        for (let j = index - 1; j >= 0; j--) {
          if (activeDrivers[j] < driversNeeded[j]) {
            const driversToAdd = Math.min(
              deficit,
              driversNeeded[j] - activeDrivers[j]
            );
            schedule[j] += driversToAdd;
            activeDrivers[j] += driversToAdd;
            activeDrivers[index] += driversToAdd;
            totalDriversCount += driversToAdd;
            if (activeDrivers[index] >= driversNeeded[index]) break;
          }
        }
      }
    }

    setTotalDrivers(totalDriversCount);
    return { schedule, activeDrivers, driversNeeded };
  }, [hours, calculateDriversNeeded]);

  useEffect(() => {
    const details = calculateOptimizedSchedule();
    setScheduleDetails(details);
  }, [calculateOptimizedSchedule]);

  const chartData = useMemo(() => 
    hours.map((hour, index) => ({
      hour: formatHourRange(hour),
      needed: parseFloat(scheduleDetails.driversNeeded[index] || 0),
      active: scheduleDetails.activeDrivers[index] || 0,
      toAdd: scheduleDetails.schedule[index] || 0
    })), [hours, scheduleDetails]
  );

  const renderChart = () => (
    <div className="mb-8 p-6 rounded-lg shadow-lg bg-white">
      <h3 className="text-xl font-bold mb-4">Driver Distribution Over Time</h3>
      <LineChart 
        width={800} 
        height={400}
        data={chartData}
        margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
      >
        <XAxis 
          dataKey="hour" 
          angle={-45} 
          textAnchor="end" 
          height={60} 
          tick={{ fill: '#4B5563', fontSize: 12 }}
        />
        <YAxis 
          tick={{ fill: '#4B5563', fontSize: 12 }}
          label={{ value: 'Number of Drivers', angle: -90, position: 'insideLeft', style: { textAnchor: 'middle' } }}
        />
        <Tooltip 
          contentStyle={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            border: 'none',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            padding: '10px'
          }}
          labelStyle={{ fontWeight: 'bold', marginBottom: '5px' }}
        />
        <Legend 
          verticalAlign="top" 
          height={36}
          wrapperStyle={{ paddingBottom: '20px' }}
        />
        <Line 
          type="monotone" 
          dataKey="needed" 
          stroke="#ef4444" 
          strokeWidth={3}
          dot={{ fill: '#ef4444', strokeWidth: 2 }}
          activeDot={{ r: 8 }}
          name="Drivers Needed"
        />
        <Line 
          type="monotone" 
          dataKey="active" 
          stroke="#3b82f6" 
          strokeWidth={3}
          dot={{ fill: '#3b82f6', strokeWidth: 2 }}
          activeDot={{ r: 8 }}
          name="Active Drivers"
        />
        <Line 
          type="monotone" 
          dataKey="toAdd" 
          stroke="#22c55e" 
          strokeWidth={3}
          dot={{ fill: '#22c55e', strokeWidth: 2 }}
          activeDot={{ r: 8 }}
          name="Drivers to Add"
        />
      </LineChart>
    </div>
  );

  return (
    <div className="flex flex-col h-full bg-gray-100">
      <div className="flex-grow overflow-auto">
        <div className="w-full max-w-6xl mx-auto p-8">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-3xl font-bold">Transaction Details</h2>
            <div className="flex items-center space-x-4">
              <div className="p-4 rounded-lg bg-blue-600 text-white w-64">
                <h4 className="text-sm font-bold mb-1">
                  Total Drivers Needed:
                </h4>
                <p className="text-3xl font-bold">{totalDrivers}</p>
              </div>
              <div className="p-4 rounded-lg bg-green-600 text-white w-64">
                <h4 className="text-sm font-bold mb-1">
                  Avg Transactions per Driver:
                </h4>
                <input
                  type="number"
                  step="0.01"
                  value={avgTransactionsPerDriver}
                  onChange={(e) => handleAvgTransactionsChange(e.target.value)}
                  className="w-full bg-green-500 text-white text-3xl font-bold p-1 rounded"
                />
              </div>
            </div>
          </div>
          {renderChart()}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="max-h-[calc(100vh-200px)] overflow-y-auto pr-4">
              <motion.div layout>
                {hours.map((hour) => (
                  <motion.div
                    key={hour}
                    className="mb-6 p-6 rounded-lg shadow-lg bg-white"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <h3 className="text-xl font-bold mb-4">
                      {formatHourRange(hour)}
                    </h3>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <div>
                        <label
                          htmlFor={`returns-${hour}`}
                          className="block text-sm font-medium mb-1"
                        >
                          Returns:
                        </label>
                        <input
                          type="text"
                          id={`returns-${hour}`}
                          value={returns[hour]}
                          onChange={(e) =>
                            handleReturnsChange(hour, e.target.value)
                          }
                          className="w-full px-3 py-2 rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-300"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor={`reservations-${hour}`}
                          className="block text-sm font-medium mb-1"
                        >
                          Reservations:
                        </label>
                        <input
                          type="text"
                          id={`reservations-${hour}`}
                          value={reservations[hour]}
                          onChange={(e) =>
                            handleReservationsChange(hour, e.target.value)
                          }
                          className="w-full px-3 py-2 rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-300"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          Average:
                        </label>
                        <div className="px-3 py-2 rounded-md bg-gray-200">
                          {calculateAverage(hour)}
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          Drivers Needed:
                        </label>
                        <div className="px-3 py-2 rounded-md bg-gray-200">
                          {calculateDriversNeeded(hour)}
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          Drivers to Add:
                        </label>
                        <div className="px-3 py-2 rounded-md bg-gray-200">
                          {(scheduleDetails.schedule[hours.indexOf(hour)] || 0).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </div>
            <div className="relative">
              <div className="sticky top-4 max-h-[calc(100vh-200px)] overflow-y-auto">
                <h3 className="text-2xl font-bold mb-6">
                  Optimized Driver Schedule
                </h3>
                <div className="p-6 rounded-lg shadow-lg bg-white">
                  {hours.map((hour, index) => (
                    <div key={hour} className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <span className="font-semibold">
                          {formatHourRange(hour)}
                        </span>
                      </div>
                      <div className="w-full h-8 bg-gray-200 rounded-lg overflow-hidden relative">
                        {scheduleDetails.driversNeeded[index] > 0 && (
                          <>
                            <div
                              className="absolute top-0 left-0 h-full bg-red-400"
                              style={{
                                width: `${Math.min(
                                  100,
                                  (scheduleDetails.driversNeeded[index] /
                                    scheduleDetails.driversNeeded[index]) *
                                    100
                                )}%`,
                              }}
                            ></div>
                            <div
                              className="absolute top-0 left-0 h-full bg-blue-400"
                              style={{
                                width: `${Math.min(
                                  100,
                                  (scheduleDetails.activeDrivers[index] /
                                    scheduleDetails.driversNeeded[index]) *
                                    100
                                )}%`,
                              }}
                            ></div>
                            <div
                              className="absolute top-0 left-0 h-full bg-green-400"
                              style={{
                                width: `${Math.min(
                                  100,
                                  (scheduleDetails.schedule[index] /
                                    scheduleDetails.driversNeeded[index]) *
                                    100
                                )}%`,
                              }}
                            ></div>
                          </>
                        )}
                        <div className="absolute inset-0 flex items-center justify-between px-2 text-xs font-bold">
                          <span>
                            Add: {scheduleDetails.schedule[index] || 0}
                          </span>
                          <span>
                            Active: {scheduleDetails.activeDrivers[index] || 0}
                          </span>
                          <span>
                            Needed: {scheduleDetails.driversNeeded[index] || 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;
