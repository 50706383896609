import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { auth, db } from "./firebase";
import CreatableSelect from "react-select/creatable";
import LocationsList from "./LocationsList";
import {
  collection,
  query,
  orderBy,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { GoogleMap, Marker, Circle } from "@react-google-maps/api";
import { GoogleMapsContext } from "./GoogleMapsProvider";
import { NumericFormat } from "react-number-format";
import { useUser } from "./UserContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

async function fetchLocationsData() {
  const q = query(collection(db, "locations"), orderBy("name"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

async function fetchRolesData() {
  const locationsSnapshot = await getDocs(collection(db, "locations"));
  const rolesByHourSet = new Set();
  const rolesByUnitSet = new Set();
  locationsSnapshot.forEach((doc) => {
    const rolesMap = doc.data().roles;
    if (rolesMap) {
      Object.keys(rolesMap).forEach((role) => {
        if (rolesMap[role] === "notByHour") {
          rolesByUnitSet.add(role);
        } else {
          rolesByHourSet.add(role);
        }
      });
    }
  });
  const fetchedRolesByHour = Array.from(rolesByHourSet).map((role) => ({
    value: role,
    label: role,
  }));
  const fetchedRolesByUnit = Array.from(rolesByUnitSet).map((role) => ({
    value: role,
    label: role,
  }));
  return {
    byHour: fetchedRolesByHour,
    byUnit: fetchedRolesByUnit,
  };
}

async function fetchTasksData() {
  const locationsSnapshot = await getDocs(collection(db, "locations"));
  const tasksByHourSet = new Set();
  const tasksByUnitSet = new Set();
  locationsSnapshot.forEach((doc) => {
    const tasksMap = doc.data().tasks;
    if (tasksMap) {
      Object.keys(tasksMap).forEach((task) => {
        if (tasksMap[task] === "notByHour") {
          tasksByUnitSet.add(task);
        } else {
          tasksByHourSet.add(task);
        }
      });
    }
  });
  const fetchedTasksByHour = Array.from(tasksByHourSet).map((task) => ({
    value: task,
    label: task,
  }));
  const fetchedTasksByUnit = Array.from(tasksByUnitSet).map((task) => ({
    value: task,
    label: task,
  }));
  return {
    byHour: fetchedTasksByHour,
    byUnit: fetchedTasksByUnit,
  };
}

async function saveLocationData({ locationData, editingLocationId }) {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("No authenticated user available");
  }

  const simplifiedTasks = {};
  Object.entries(locationData.tasks).forEach(([taskName, taskDetails]) => {
    simplifiedTasks[taskName] = {
      type: taskDetails.type,
      price: taskDetails.price,
    };
  });

  const dataToSave = {
    ...locationData,
    tasks: simplifiedTasks,
    lastModifiedBy: user.uid,
    lastModifiedAt: Timestamp.now(),
  };

  if (editingLocationId) {
    await updateDoc(doc(db, "locations", editingLocationId), dataToSave);
  } else {
    await addDoc(collection(db, "locations"), dataToSave);
  }
}

function Locations({ userRole: propUserRole }) {
  const { userRole: contextUserRole } = useUser();
  const effectiveUserRole = propUserRole || contextUserRole;

  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const addressInputRef = useRef(null);
  const [locationForm, setLocationForm] = useState({
    name: "",
    address: "",
    supervisors: [],
    radius: 1000,
    latitude: null,
    longitude: null,
    rolesByHour: [],
    rolesByUnit: [],
    tasks: {},
  });
  const [editingLocationId, setEditingLocationId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [map, setMap] = useState(null);

  const {
    data: locations,
    isLoading: isLoadingLocations,
    isError,
    error,
  } = useQuery({
    queryKey: ["locations"],
    queryFn: fetchLocationsData,
    staleTime: 5 * 60 * 1000,
  });

  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: fetchRolesData,
    staleTime: 5 * 60 * 1000,
  });

  const { data: tasks } = useQuery({
    queryKey: ["tasks"],
    queryFn: fetchTasksData,
    staleTime: 5 * 60 * 1000,
  });

  const saveLocationMutation = useMutation({
    mutationFn: saveLocationData,
    onSuccess: () => {
      queryClient.invalidateQueries(["locations"]);
      closeModal();
    },
    onError: (error) => {
      console.error("Error saving location: ", error);
    },
  });

  const { isLoaded: isLoadedMaps, loadError } =
    React.useContext(GoogleMapsContext);

  // Use useMemo for filteredLocations
  const filteredLocations = useMemo(() => {
    return locations
      ? locations.filter((location) =>
          (
            location.name.toLowerCase() +
            " " +
            location.address.toLowerCase()
          ).includes(searchTerm.toLowerCase())
        )
      : [];
  }, [locations, searchTerm]);

  const onMapLoad = useCallback((map) => {
    setMap(map);
  }, []);

  useEffect(() => {
    if (filteredLocations.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();
      let validLocations = 0;

      filteredLocations.forEach((location) => {
        if (
          typeof location.latitude === "number" &&
          typeof location.longitude === "number"
        ) {
          bounds.extend({ lat: location.latitude, lng: location.longitude });
          validLocations++;
        }
      });

      if (validLocations > 0) {
        map.fitBounds(bounds);
        if (validLocations === 1) {
          map.setZoom(13);
        }
      }
    }
  }, [filteredLocations, map]);

  useEffect(() => {
    if (isModalOpen && isLoadedMaps && addressInputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressInputRef.current,
        { types: ["geocode"] }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (place.geometry) {
          setLocationForm((prevState) => ({
            ...prevState,
            address: place.formatted_address,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          }));
        }
      });

      return () => {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      };
    }
  }, [isLoadedMaps, isModalOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocationForm((prevState) => ({
      ...prevState,
      [name]: name === "radius" ? Number(value) : value,
    }));
  };

  const handleAddLocation = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setLocationForm({
      name: "",
      address: "",
      supervisors: [],
      radius: 1000,
      latitude: null,
      longitude: null,
      rolesByHour: [],
      rolesByUnit: [],
      tasks: {},
    });
    setEditingLocationId(null);
    if (addressInputRef.current) {
      addressInputRef.current.value = "";
    }
  };

  const handleSaveLocation = () => {
    const rolesMap = locationForm.rolesByHour.reduce((acc, current) => {
      acc[current.value] = "";
      return acc;
    }, {});

    locationForm.rolesByUnit.forEach((current) => {
      rolesMap[current.value] = "notByHour";
    });

    const locationData = {
      ...locationForm,
      radius: Number(locationForm.radius),
      roles: rolesMap,
      lastModifiedBy: auth.currentUser.uid,
      lastModifiedAt: Timestamp.now(),
    };

    saveLocationMutation.mutate({ locationData, editingLocationId });
  };

  const handleEditLocation = (location) => {
    const rolesByHour = [];
    const rolesByUnit = [];
    Object.entries(location.roles || {}).forEach(([role, value]) => {
      if (value === "notByHour") {
        rolesByUnit.push({ value: role, label: role });
      } else {
        rolesByHour.push({ value: role, label: role });
      }
    });

    const tasksByHour = [];
    const tasksByUnit = [];
    const taskPrices = {};
    Object.entries(location.tasks || {}).forEach(([task, value]) => {
      const taskOption = { value: task, label: task };
      if (value.type === "byHour") {
        tasksByHour.push(taskOption);
      } else {
        tasksByUnit.push(taskOption);
      }
      taskPrices[task] = value.price;
    });

    setLocationForm({
      name: location.name || "",
      address: location.address || "",
      latitude: location.latitude || null,
      longitude: location.longitude || null,
      radius: location.radius || 1000,
      rolesByHour,
      rolesByUnit,
      tasks: location.tasks || {},
    });
    setEditingLocationId(location.id);
    setIsModalOpen(true);
  };

  const handleLocationClick = (location) => {
    setSearchTerm(location.name);
    if (map) {
      map.panTo({ lat: location.latitude, lng: location.longitude });
      map.setZoom(15);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "" && map) {
      const bounds = new window.google.maps.LatLngBounds();
      filteredLocations.forEach((location) => {
        if (
          typeof location.latitude === "number" &&
          typeof location.longitude === "number"
        ) {
          bounds.extend({ lat: location.latitude, lng: location.longitude });
        }
      });
      map.fitBounds(bounds);
    }
  };

  const handleTaskChange = (taskName, field, value) => {
    setLocationForm((prevState) => ({
      ...prevState,
      tasks: {
        ...prevState.tasks,
        [taskName]: {
          ...prevState.tasks[taskName],
          [field]: value,
        },
      },
    }));
  };

  const handleDeleteTask = (taskName) => {
    setLocationForm((prevState) => {
      const updatedTasks = { ...prevState.tasks };
      delete updatedTasks[taskName];
      return { ...prevState, tasks: updatedTasks };
    });
  };

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    marginBottom: "20px",
    borderRadius: "15px",
    overflow: "hidden",
  };

  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
  };

  if (isLoadingLocations || !isLoadedMaps) {
    return (
      <div className="space-y-4">
        {/* Map skeleton */}
        <Skeleton height={400} borderRadius={15} />

        {/* Search bar skeleton */}
        <Skeleton height={40} />

        {/* Location list skeletons */}
        {[...Array(5)].map((_, index) => (
          <div key={index} className="mb-4 p-4 bg-white rounded-lg shadow">
            <div className="flex justify-between">
              <div className="space-y-2 w-full">
                <Skeleton width={200} />
                <Skeleton width="80%" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (isError) {
    return <div>Error loading locations: {error.message}</div>;
  }

  if (loadError) {
    return <div>Error loading Google Maps: {loadError.message}</div>;
  }

  return (
    <div className="relative">
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
          id="my-modal"
        >
          <div className="relative top-20 mx-auto p-6 border shadow-lg rounded-lg bg-white w-11/12 max-w-2xl z-50">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-2xl font-semibold text-gray-900">
                {editingLocationId ? "Edit Location" : "Add New Location"}
              </h3>
              <button
                onClick={closeModal}
                className="text-gray-400 hover:text-gray-600"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label
                  htmlFor="location-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Location Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={locationForm.name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  id="location-name"
                  placeholder="Enter location name"
                />
              </div>

              <div>
                <label
                  htmlFor="location-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address
                </label>
                <input
                  type="text"
                  ref={addressInputRef}
                  name="address"
                  value={locationForm.address}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  id="location-address"
                  placeholder="Enter location address"
                />
              </div>

              <div>
                <label
                  htmlFor="rolesByHour"
                  className="block text-sm font-medium text-gray-700"
                >
                  Positions By Hour
                </label>
                <CreatableSelect
                  isMulti
                  name="rolesByHour"
                  options={roles?.byHour}
                  value={locationForm.rolesByHour}
                  className="mt-1"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    setLocationForm((prevState) => ({
                      ...prevState,
                      rolesByHour: selectedOptions,
                    }))
                  }
                  onCreateOption={(inputValue) => {
                    const newOption = { value: inputValue, label: inputValue };
                    queryClient.setQueryData(["roles"], (oldRoles) => ({
                      ...oldRoles,
                      byHour: [...oldRoles.byHour, newOption],
                    }));
                    setLocationForm((prevState) => ({
                      ...prevState,
                      rolesByHour: [...prevState.rolesByHour, newOption],
                    }));
                  }}
                />
              </div>

              <div>
                <label
                  htmlFor="rolesByUnit"
                  className="block text-sm font-medium text-gray-700"
                >
                  Positions By Unit
                </label>
                <CreatableSelect
                  isMulti
                  name="rolesByUnit"
                  options={roles?.byUnit}
                  value={locationForm.rolesByUnit}
                  className="mt-1"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    setLocationForm((prevState) => ({
                      ...prevState,
                      rolesByUnit: selectedOptions,
                    }))
                  }
                  onCreateOption={(inputValue) => {
                    const newOption = { value: inputValue, label: inputValue };
                    queryClient.setQueryData(["roles"], (oldRoles) => ({
                      ...oldRoles,
                      byUnit: [...oldRoles.byUnit, newOption],
                    }));
                    setLocationForm((prevState) => ({
                      ...prevState,
                      rolesByUnit: [...prevState.rolesByUnit, newOption],
                    }));
                  }}
                />
              </div>

              {effectiveUserRole === "owner" ? (
                <>
                  <div>
                    <label
                      htmlFor="tasks"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Cleaning Tasks
                    </label>
                    <div className="mt-1 space-y-2">
                      {Object.entries(locationForm.tasks).map(
                        ([taskName, taskDetails]) => (
                          <div
                            key={taskName}
                            className="flex items-center space-x-2"
                          >
                            <input
                              type="text"
                              value={taskName}
                              readOnly
                              className="flex-grow rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                            <select
                              value={taskDetails.type}
                              onChange={(e) =>
                                handleTaskChange(
                                  taskName,
                                  "type",
                                  e.target.value
                                )
                              }
                              className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            >
                              <option value="byHour">By Hour</option>
                              <option value="byUnit">By Unit</option>
                            </select>
                            <NumericFormat
                              value={taskDetails.price || ""}
                              onValueChange={(values) =>
                                handleTaskChange(
                                  taskName,
                                  "price",
                                  values.floatValue
                                )
                              }
                              thousandSeparator={true}
                              prefix={"$"}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowNegative={false}
                              className="w-24 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              placeholder="$ Price"
                            />
                            <button
                              onClick={() => handleDeleteTask(taskName)}
                              className="p-2 text-red-600 hover:text-red-800 focus:outline-none"
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </div>
                        )
                      )}
                    </div>
                    <CreatableSelect
                      name="tasks"
                      options={[...tasks?.byHour, ...tasks?.byUnit]}
                      className="mt-1"
                      classNamePrefix="select"
                      onChange={(newValue) => {
                        if (newValue) {
                          handleTaskChange(newValue.value, "type", "byHour");
                          handleTaskChange(newValue.value, "price", 0);
                        }
                      }}
                      onCreateOption={(inputValue) => {
                        handleTaskChange(inputValue, "type", "byHour");
                        handleTaskChange(inputValue, "price", 0);
                      }}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}

              <div>
                <label
                  htmlFor="radius"
                  className="block text-sm font-medium text-gray-700"
                >
                  Radius (meters)
                </label>
                <input
                  type="number"
                  name="radius"
                  value={locationForm.radius}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  id="radius"
                  placeholder="1000"
                />
              </div>
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveLocation}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      <h1 className="text-lg font-bold text-white uppercase tracking-wider mb-4">
        Locations
      </h1>

      <div
        style={{
          borderRadius: "15px",
          overflow: "hidden",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          onLoad={onMapLoad}
          options={mapOptions}
        >
          {filteredLocations.map((location) => (
            <React.Fragment key={location.id}>
              <Marker
                position={{ lat: location.latitude, lng: location.longitude }}
                onClick={() => handleLocationClick(location)}
              />
              <Circle
                center={{ lat: location.latitude, lng: location.longitude }}
                radius={location.radius}
                options={{
                  fillColor:
                    searchTerm === location.name ? "#4299e1" : "#3182ce",
                  fillOpacity: 0.35,
                  strokeColor: "#3182ce",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                }}
              />
            </React.Fragment>
          ))}
        </GoogleMap>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name or address..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
        />
      </div>

      <LocationsList
        locations={filteredLocations}
        onEditLocation={handleEditLocation}
        onLocationClick={handleLocationClick}
        selectedLocation={searchTerm}
        userRole={effectiveUserRole}
      />
      {effectiveUserRole !== "director" &&
        effectiveUserRole !== "consultant" && (
          <button
            onClick={handleAddLocation}
            className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-full shadow-lg flex items-center justify-center"
            style={{
              width: "56px",
              height: "56px",
              fontSize: "24px",
              borderRadius: "50%",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        )}
    </div>
  );
}

export default Locations;
