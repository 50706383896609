import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";

const UniquePunch = () => {
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  const openAppLink = () => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const iOSLink = "https://apps.apple.com/us/app/unique-punch/id6463495209";
    const androidLink =
      "https://play.google.com/store/apps/details?id=com.rickyalves.uniquePunch";

    if (isIOS) {
      window.open(iOSLink, "_blank");
    } else if (/android/i.test(navigator.userAgent)) {
      window.open(androidLink, "_blank");
    } else {
      window.open(iOSLink, "_blank");
      window.open(androidLink, "_blank");
    }
  };

  const iOSLink = "https://apps.apple.com/us/app/unique-punch/id6463495209";
  const appId = "6463495209";

  const faqData = [
    {
      question: "I forgot my password or my password isn't working. How do I fix this?",
      answer: "Just try logging into Unique Punch using the email address you registered in the application and the default password: \"unique1234.\" Just remember to use the email address you have on file in the Hub. If it's incorrect, let your supervisor know, so we can change it for you."
    },
    {
      question: "How does the app's location system work?",
      answer: "The app uses your current location to verify whether you are at your workplace. When you are in the correct location, open the app and you should see the name of your location at the top of the screen. From there, simply select the position you will be working in and swipe to \"Punch In.\"\n\nIf the app shows \"Outside Location,\" it means you're not within the radius of the place where you're supposed to be working. This may happen if you are at home or somewhere else you shouldn't be. If the location name is incorrect, you can use the map button in the top right corner of the app to see your exact location and nearby locations marked with red pins. Tap the pins to see the name of each location.\n\nTo be in the correct location, you must remain within the circle that represents your workplace location. If you have trouble viewing your position, try closing and reopening the app to refresh the location and position data."
    },
    {
      question: "What should I do if the problem persists?",
      answer: "If you are still having trouble, inform your supervisor."
    },
    {
      question: "What happens after I punch in?",
      answer: "Once you punch in, the work hours timer starts counting. If you need to switch positions during your shift, simply change the position in the app without punching out."
    },
    {
      question: "How do breaks work?",
      answer: "To take a break, press the \"Break\" button. You can take as many breaks as you like, as long as their total duration doesn't exceed 30 minutes. The break time will be deducted from your worked hours, of course."
    }
  ];

  return (
    <HelmetProvider>
      <Helmet>
        <title>Unique Punch</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="apple-itunes-app"
          content={`app-id=${appId}, affiliate-data=myAffiliateData, app-argument=${iOSLink}`}
        />
      </Helmet>
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-600 via-purple-500 to-pink-500 p-4">
        <div className="flex flex-col items-center justify-center text-center p-6 bg-white/30 backdrop-blur-md border border-white/10 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 max-w-md w-full">
          <div className="text-white text-5xl md:text-6xl audiowide mb-4">
            UNIQUE
          </div>
          <div className="text-white text-5xl md:text-6xl audiowide">PUNCH</div>
          <p className="text-base md:text-lg mb-6 md:mb-10 text-gray-100">
            Effortlessly manage your time and attendance.
          </p>
          <button
            onClick={openAppLink}
            className="flex items-center justify-center gap-2 px-6 py-3 md:px-8 md:py-4 bg-gradient-to-r from-blue-500 to-green-400 text-white font-semibold rounded-full shadow-lg hover:shadow-2xl transition duration-300 transform hover:-translate-y-1 hover:scale-110"
            aria-label="Download or Update App"
          >
            <FontAwesomeIcon icon={faApple} className="text-lg md:text-xl" />
            <FontAwesomeIcon icon={faAndroid} className="text-lg md:text-xl" />
            Download the App
          </button>
          
          {/* FAQ Section */}
          <div className="mt-12 w-full">
            <h2 className="text-2xl font-bold text-white mb-6">FAQ</h2>
            <div className="space-y-4">
              {faqData.map((faq, index) => (
                <div 
                  key={index} 
                  className="text-left bg-white/10 rounded-lg overflow-hidden"
                >
                  <button
                    className="w-full px-4 py-3 text-left flex justify-between items-center"
                    onClick={() => setOpenFaqIndex(openFaqIndex === index ? null : index)}
                  >
                    <h3 className="text-lg font-semibold text-white pr-4">{faq.question}</h3>
                    <span className="text-white text-xl transform transition-transform duration-200" style={{
                      transform: openFaqIndex === index ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}>
                      ▼
                    </span>
                  </button>
                  <div 
                    className="overflow-hidden transition-all duration-200 ease-in-out"
                    style={{
                      maxHeight: openFaqIndex === index ? '500px' : '0',
                      opacity: openFaqIndex === index ? 1 : 0
                    }}
                  >
                    <p className="text-gray-100 text-sm whitespace-pre-line px-4 pb-4">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default UniquePunch;
