import React, { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, Cell, LineChart, Line
} from "recharts";
import { db } from "./firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StatisticsSkeleton = () => (
  <div className="container mx-auto px-4 py-8 bg-gray-50 min-h-screen">
    {/* Date Picker Skeleton */}
    <div className="mb-8">
      <div className="w-72 h-10 bg-gray-200 animate-pulse rounded"></div>
    </div>

    {/* Summary Cards Skeleton */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
      {[1, 2, 3].map((i) => (
        <div key={i} className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <div className="flex items-center justify-between">
            <div className="w-full">
              <div className="h-4 bg-gray-200 animate-pulse rounded w-1/2 mb-2"></div>
              <div className="h-8 bg-gray-200 animate-pulse rounded w-3/4"></div>
            </div>
          </div>
        </div>
      ))}
    </div>

    {/* Charts Skeleton */}
    {[1, 2, 3].map((i) => (
      <div key={i} className="mb-8">
        <div className="h-8 bg-gray-200 animate-pulse rounded w-48 mb-4"></div>
        <div className="h-96 bg-gray-200 animate-pulse rounded"></div>
      </div>
    ))}
  </div>
);

function Statistics() {
  const [dateRange, setDateRange] = useState([
    new Date(new Date().setDate(new Date().getDate() - 30)), // Last 30 days
    new Date()
  ]);
  const [startDate, endDate] = dateRange;

  // Fetch tasks data
  const { data: tasksData, isLoading } = useQuery({
    queryKey: ["statistics", startDate, endDate],
    queryFn: () => fetchTasksForStats(startDate, endDate),
  });

  // Memoized statistics calculations
  const statistics = useMemo(() => {
    if (!tasksData) return null;

    // Group data by location and shift to calculate tasks per shift
    const locationStats = tasksData.reduce((acc, task) => {
      const location = task.locationName || "Unknown";
      if (!acc[location]) {
        acc[location] = {
          location,
          taskCount: 0,
          revenue: 0,
          payroll: 0,
          grossProfit: 0,
          totalHours: 0,
          totalTasks: [],
        };
      }
      
      // Calculate total hours for the shift considering role changes
      let totalHours = 0;
      if (task.shift?.startTimestamp) {
        const shiftStart = task.shift.startTimestamp.toDate();
        const shiftEnd = task.shift.endTimestamp ? task.shift.endTimestamp.toDate() : new Date();
        
        // Calculate total milliseconds only for "Vin Collect" periods
        let totalMilliseconds = 0;

        if (task.shift.roleChanges && task.shift.roleChanges.length > 0) {
          const roleChanges = [...task.shift.roleChanges].sort(
            (a, b) => a.timestamp.seconds - b.timestamp.seconds
          );

          let lastVinCollectStart = null;
          let currentRole = roleChanges[0].role;

          // Add shift start if first role is "Vin Collect"
          if (currentRole === "Vin Collect") {
            lastVinCollectStart = shiftStart;
          }

          // Process role changes
          for (let i = 1; i < roleChanges.length; i++) {
            const changeTime = roleChanges[i].timestamp.toDate();

            if (currentRole === "Vin Collect" && lastVinCollectStart) {
              totalMilliseconds += changeTime - lastVinCollectStart;
              lastVinCollectStart = null;
            } else if (roleChanges[i].role === "Vin Collect") {
              lastVinCollectStart = changeTime;
            }

            currentRole = roleChanges[i].role;
          }

          // Handle final period if ended in "Vin Collect"
          if (currentRole === "Vin Collect" && lastVinCollectStart) {
            totalMilliseconds += shiftEnd - lastVinCollectStart;
          }
        } else {
          // If no role changes, assume entire shift was Vin Collect
          totalMilliseconds = shiftEnd - shiftStart;
        }

        // Subtract breaks if any
        if (task.shift.breakTimestamps && task.shift.breakTimestamps.length > 1) {
          for (let i = 0; i < task.shift.breakTimestamps.length; i += 2) {
            const breakStart = new Date(task.shift.breakTimestamps[i]);
            const breakEnd = task.shift.breakTimestamps[i + 1]
              ? new Date(task.shift.breakTimestamps[i + 1])
              : new Date();

            if (breakStart >= shiftStart && breakEnd <= shiftEnd) {
              totalMilliseconds -= breakEnd - breakStart;
            }
          }
        }

        totalHours = totalMilliseconds / (1000 * 60 * 60);
        acc[location].totalHours += totalHours;
      }

      acc[location].taskCount++;
      const revenue = task.price || 0;
      acc[location].revenue += revenue;

      // Calculate payroll using the same logic as VinCollect.js
      let payroll = 0;
      const hourlyRate = parseFloat(task.shift?.rate) || parseFloat(task.user?.rate) || 0;

      if (hourlyRate > 0) {
        payroll = totalHours * hourlyRate;
      } else if (task.user?.salary && !isNaN(parseFloat(task.user.salary))) {
        const hourlyWage = parseFloat(task.user.salary) / (260 * 8);
        payroll = totalHours * hourlyWage;
      }

      acc[location].payroll += payroll;
      acc[location].grossProfit += revenue - payroll;
      acc[location].totalTasks.push({
        ...task,
        totalHours,
        payroll,
        grossProfit: revenue - payroll
      });
      
      return acc;
    }, {});

    // Calculate averages and prepare chart data
    const locationData = Object.values(locationStats).map(stat => ({
      ...stat,
      averageTasksPerHour: stat.totalHours > 0 ? stat.taskCount / stat.totalHours : 0,
      grossProfitPercentage: stat.revenue > 0 ? (stat.grossProfit / stat.revenue) * 100 : 0,
    }));

    return {
      locationData,
      dailyData: getDailyTrends(tasksData),
      totalRevenue: locationData.reduce((sum, loc) => sum + loc.revenue, 0),
      totalPayroll: locationData.reduce((sum, loc) => sum + loc.payroll, 0),
      totalProfit: locationData.reduce((sum, loc) => sum + loc.grossProfit, 0),
      totalTasks: locationData.reduce((sum, loc) => sum + loc.taskCount, 0),
    };
  }, [tasksData]);

  if (isLoading) return <StatisticsSkeleton />;

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-50 min-h-screen">
      {/* Date Range Picker */}
      <div className="mb-8">
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={setDateRange}
          className="p-2 border rounded bg-white"
        />
      </div>

      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <SummaryCard
          title="Total Revenue"
          value={`$${statistics?.totalRevenue.toFixed(2)}`}
          icon="💰"
        />
        <SummaryCard
          title="Total Tasks"
          value={statistics?.totalTasks}
          icon="✓"
        />
      </div>

      {/* Location Performance Chart */}
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Location Performance</h2>
        <div className="h-96">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={statistics?.locationData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="location" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip formatter={(value) => `$${Number(value).toFixed(2)}`} />
              <Legend />
              <Bar 
                yAxisId="left" 
                dataKey="revenue" 
                fill="#8884d8" 
                name="Revenue" 
              />
              <Bar 
                yAxisId="left" 
                dataKey="grossProfit" 
                fill="#82ca9d" 
                name="Gross Profit" 
              />
              <Bar 
                yAxisId="right" 
                dataKey="grossProfitPercentage" 
                fill="#ffc658" 
                name="Profit Margin %" 
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Daily Trends */}
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Daily Trends</h2>
        <div className="h-96">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={statistics?.dailyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="tasks" stroke="#8884d8" name="Tasks" />
              <Line type="monotone" dataKey="revenue" stroke="#82ca9d" name="Revenue" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Revenue Distribution Pie Chart */}
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Revenue Distribution</h2>
        <div className="h-96">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={prepareRevenueData(statistics?.locationData)}
              layout="vertical"
              margin={{ left: 150 }} // Adjust this value based on your longest location name
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" tickFormatter={(value) => `$${value.toLocaleString()}`} />
              <YAxis 
                type="category" 
                dataKey="location" 
                width={140}
                tick={{ fontSize: 12 }}
              />
              <Tooltip 
                formatter={(value) => `$${value.toLocaleString()}`}
              />
              <Bar 
                dataKey="revenue" 
                fill="#8884d8"
                background={{ fill: '#eee' }}
              >
                {prepareRevenueData(statistics?.locationData).map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

// Helper Components and Constants
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const SummaryCard = ({ title, value, icon }) => (
  <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm text-gray-500">{title}</p>
        <p className="text-2xl font-bold text-gray-800">{value}</p>
      </div>
      <div className="text-3xl">{icon}</div>
    </div>
  </div>
);

// Helper function to get daily trends
const getDailyTrends = (tasks) => {
  const dailyData = tasks.reduce((acc, task) => {
    const date = new Date(task.startTimestamp.seconds * 1000).toLocaleDateString();
    if (!acc[date]) {
      acc[date] = { date, tasks: 0, revenue: 0 };
    }
    acc[date].tasks++;
    acc[date].revenue += task.price || 0;
    return acc;
  }, {});

  return Object.values(dailyData).sort((a, b) => new Date(a.date) - new Date(b.date));
};

// Helper function to fetch tasks
const fetchTasksForStats = async (startDate, endDate) => {
  const tasksCollection = collection(db, "tasks");
  const startTimestamp = new Date(startDate);
  startTimestamp.setHours(0, 0, 0, 0);
  const endTimestamp = new Date(endDate);
  endTimestamp.setHours(23, 59, 59, 999);

  const tasksQuery = query(
    tasksCollection,
    where("startTimestamp", ">=", startTimestamp),
    where("startTimestamp", "<=", endTimestamp)
  );

  const querySnapshot = await getDocs(tasksQuery);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

// Add this helper function near other helper functions
const prepareRevenueData = (locationData) => {
  // Sort locations by revenue
  const sortedLocations = [...locationData].sort((a, b) => b.revenue - a.revenue);
  
  // Take top 8 locations
  const topLocations = sortedLocations.slice(0, 8);
  
  // Combine the rest into "Others"
  const othersRevenue = sortedLocations
    .slice(8)
    .reduce((sum, loc) => sum + loc.revenue, 0);
  
  if (othersRevenue > 0) {
    topLocations.push({
      location: "Others",
      revenue: othersRevenue
    });
  }
  
  return topLocations;
};

export default Statistics;
    