import React, { useMemo, useState, useCallback } from "react";
import { db } from "./firebase";
import { collection, query, getDocs, where } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Salary = () => {
  const formatCurrency = useCallback((number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  }, []);

  const fetchEmployees = async () => {
    const usersRef = collection(db, "users");
    const q = query(
      usersRef,
      where("status", "==", "Active"),
      where("salary", "!=", null)
    );
    const querySnapshot = await getDocs(q);
    const employeeData = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      const salaryNumber = parseFloat(data.salary) || 0;
      return {
        id: doc.id,
        firstName: data.firstName || "Unknown",
        lastName: data.lastName || "Unknown",
        companyName: data.companyName || "",
        location: data.workLocation || "Unknown",
        payMethod: data.payMethod || "Unknown",
        salary: salaryNumber,
        weeklySalary: salaryNumber / 52,
      };
    });

    return employeeData
      .filter((employee) => employee.salary > 0)
      .sort((a, b) => {
        const aName = a.companyName || `${a.firstName} ${a.lastName}`;
        const bName = b.companyName || `${b.firstName} ${b.lastName}`;
        return aName.localeCompare(bName);
      });
  };

  const {
    data: employees,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["employees"],
    queryFn: fetchEmployees,
  });

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsHeader = [
      [
        "Name",
        "Work Location",
        "Pay Method",
        "Annual Salary",
        "Weekly Salary",
      ],
    ];

    const wsData = employees.map((employee) => [
      employee.companyName || `${employee.lastName}, ${employee.firstName}`,
      employee.location,
      employee.payMethod,
      formatCurrency(employee.salary),
      formatCurrency(employee.weeklySalary),
    ]);

    const ws = XLSX.utils.aoa_to_sheet(wsHeader.concat(wsData));
    XLSX.utils.book_append_sheet(wb, ws, "Employee Salaries");

    const filename = `employee_salaries_${
      new Date().toISOString().split("T")[0]
    }.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  const [sorting, setSorting] = useState([
    {
      id: "firstName",
      desc: false,
    },
  ]);

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorFn: (row) => row.companyName || `${row.lastName}, ${row.firstName}`,
        cell: ({ row }) => 
          row.original.companyName || `${row.original.lastName}, ${row.original.firstName}`,
      },
      {
        header: "Location",
        accessorKey: "location",
      },
      {
        header: "Pay Method",
        accessorKey: "payMethod",
      },
      {
        header: "Annual Salary",
        accessorKey: "salary",
        cell: ({ getValue }) => formatCurrency(getValue()),
      },
      {
        header: "Weekly Salary",
        accessorKey: "weeklySalary",
        cell: ({ getValue }) => formatCurrency(getValue()),
      },
    ],
    [formatCurrency]
  );

  const table = useReactTable({
    data: employees || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  if (error) {
    return (
      <div className="bg-[#1F2937] min-h-screen text-white p-8">
        Error: {error.message}
      </div>
    );
  }

  return (
    <div className="bg-[#1F2937] min-h-screen text-white p-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Employee Salaries</h1>
        <button
          onClick={exportToExcel}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
        >
          <FontAwesomeIcon icon={faFileExport} className="mr-2" /> Export
        </button>
      </div>
      {isLoading ? (
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  {[...Array(5)].map((_, index) => (
                    <th key={index} className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200">
                      <Skeleton width={100} />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {[...Array(5)].map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    {[...Array(5)].map((_, colIndex) => (
                      <td key={colIndex} className="px-5 py-5 border-b border-gray-200 bg-white">
                        <Skeleton width={colIndex === 0 ? 150 : 100} />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : employees && employees.length === 0 ? (
        <p>No employees found with salary information.</p>
      ) : (
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider cursor-pointer"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <span>
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted()] ?? null}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-gray-700"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Salary;
