import React, { useState, useCallback } from "react";
import {
  faArrowUpZA,
  faArrowUpAZ,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function EmployeeList({
  employees,
  onRowClick,
  sortField,
  sortDirection,
  handleSortToggle,
  userRole,
  getWarningIconData,
  onWarningClick,
}) {
  const [panelWidth, setPanelWidth] = useState('100%');

  // Callback to handle the mouse move event
  const handleMouseMove = useCallback((e) => {
    // Calculate the new width based on the mouse movement
    setPanelWidth((currentWidth) => currentWidth + e.movementX);
  }, []);

  // Callback to clean up event listeners
  const handleMouseUp = useCallback(() => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  }, [handleMouseMove]);

  // Callback to start listening to mouse move events
  const handleMouseDown = useCallback(
    (e) => {
      e.preventDefault();
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [handleMouseMove, handleMouseUp]
  );

  return (
    <div className="relative w-full max-w-[1200px]" style={{ width: panelWidth }}>
      <div className="overflow-x-auto rounded-lg">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                <div className="flex justify-between items-center">
                  Name
                  <FontAwesomeIcon
                    icon={
                      sortField === "firstName" && sortDirection === "asc"
                        ? faArrowUpZA
                        : faArrowUpAZ
                    }
                    onClick={() => handleSortToggle("firstName")}
                  />
                </div>
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                <div className="flex justify-between items-center">
                  Position
                  <FontAwesomeIcon
                    icon={
                      sortField === "position" && sortDirection === "asc"
                        ? faArrowUpZA
                        : faArrowUpAZ
                    }
                    onClick={() => handleSortToggle("position")}
                  />{" "}
                </div>
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                <div className="flex justify-between items-center">
                  Status
                  <FontAwesomeIcon icon={faArrowUpZA} />
                </div>
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                <div className="flex justify-between items-center">
                  Warning
                  <FontAwesomeIcon icon={faArrowUpZA} />
                </div>
              </th>
              {userRole !== 'consultant' && (
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {employees.map((employee, index) => {
              // Generate a unique key for each row
              const rowKey = employee.objectID || employee.id || `employee-${index}`;
              
              const { warningIcon, warningColor } = getWarningIconData(
                employee.warnings
              );
              let phoneNumber = employee.phone;
              let isValidPhoneNumber = true;

              // Ensure phone number is a string
              if (typeof phoneNumber !== "string") {
                phoneNumber = String(phoneNumber);
              }

              // Check for US format without leading "1"
              if (
                phoneNumber &&
                phoneNumber.length === 10 &&
                !isNaN(phoneNumber)
              ) {
                phoneNumber = "1" + phoneNumber; // Add the leading "1" for US format
              } else if (
                phoneNumber &&
                phoneNumber.length === 11 &&
                phoneNumber.startsWith("1") &&
                !isNaN(phoneNumber)
              ) {
              } else if (
                phoneNumber &&
                phoneNumber.length === 11 &&
                !phoneNumber.startsWith("55") &&
                !isNaN(phoneNumber)
              ) {
                phoneNumber = "55" + phoneNumber; // Add the leading "55" for Brazilian format
              } else if (
                phoneNumber &&
                phoneNumber.length === 13 &&
                phoneNumber.startsWith("55") &&
                !isNaN(phoneNumber)
              ) {
                // Already in the correct format
              } else {
                isValidPhoneNumber = false;
              }

              return (
                <tr key={rowKey}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className="font-medium text-lg">
                      {employee.firstName} {employee.middleName}{" "}
                      {employee.lastName}
                    </div>
                    <div className="mt-2 flex space-x-4">
                      {/* WhatsApp Button */}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isValidPhoneNumber) {
                            let whatsappUrl = `https://wa.me/${phoneNumber}`;
                            // Check if applicationComplete exists and is false
                            if (
                              employee.hasOwnProperty("applicationComplete") &&
                              employee.applicationComplete === false
                            ) {
                              const message = encodeURIComponent(
                                `Welcome to the Unique team! 🌟 To complete your application, please visit our portal at https://hub.uniqueonthego.com and log in with your email and the standard password "unique1234". Let's get started on this exciting journey together!`
                              );
                              whatsappUrl += `?text=${message}`;
                            }
                            window.open(whatsappUrl, "_blank");
                          }
                        }}
                        className={`flex items-center justify-center font-bold w-36 py-2 ${
                          isValidPhoneNumber ? "bg-[#27D366]" : "bg-gray-300"
                        } text-white rounded hover:opacity-90`}
                        title="WhatsApp"
                        disabled={!isValidPhoneNumber}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-7 w-7 mr-4"
                          fill="currentColor"
                          style={{ color: "#128c7e" }}
                          viewBox="0 0 24 24"
                        >
                          <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                      </svg>
                      WhatsApp
                    </button>
                    {/* Email Button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent any parent click handlers from being called
                        window.location.href = `mailto:${employee.email}`;
                      }}
                      className="flex items-center justify-center font-bold w-36 py-2 bg-gray-500 text-white rounded hover:opacity-90"
                      title="Email"
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="h-7 w-7 mr-6"
                      />
                      Email
                    </button>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {Array.isArray(employee.position)
                    ? employee.position.join(", ")
                    : employee.position || "N/A"}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {employee.status === "Active" ? (
                    <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                      <span
                        aria-hidden
                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                      ></span>
                      <span className="relative">Active</span>
                    </span>
                  ) : employee.status === "inactive" ? (
                    <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                      <span
                        aria-hidden
                        className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                      ></span>
                      <span className="relative">Inactive</span>
                    </span>
                  ) : (
                    <span className="relative inline-block px-3 py-1 font-semibold text-white leading-tight bg-black rounded-full">
                      <span
                        aria-hidden
                        className="absolute inset-0 bg-black opacity-50 rounded-full"
                      ></span>
                      <span className="relative">Terminated</span>
                    </span>
                  )}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-center align-middle">
                  <FontAwesomeIcon
                    icon={warningIcon}
                    className="fa-3x"
                    style={{ color: warningColor }}
                    onClick={() => onWarningClick(employee)}
                  />
                </td>
                {userRole !== 'consultant' && (
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <button
                      onClick={() => onRowClick(employee)}
                      className="px-4 py-2 bg-blue-500 font-bold text-white rounded hover:bg-blue-700"
                      title="Details"
                    >
                      Details
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
      <div
        className="cursor-ew-resize w-2 h-full absolute top-0 right-0 bg-gray-300"
        onMouseDown={handleMouseDown}
      >
        {/* Resize handle */}
      </div>
    </div>
  );
}

export default EmployeeList;