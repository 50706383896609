import React, { useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  format,
  subMonths,
  isWithinInterval,
  startOfMonth,
  endOfMonth,
  startOfWeek,
} from "date-fns";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function AccidentStats({ accidents, isLoading }) {
  // Calculate trends by comparing current month with previous month
  const trends = useMemo(() => {
    const now = new Date();
    const currentMonth = startOfMonth(now);
    const lastMonth = startOfMonth(subMonths(now, 1));
    const twoMonthsAgo = startOfMonth(subMonths(now, 2));

    // Helper function to count accidents in a date range
    const countAccidentsInRange = (start, end) => {
      return accidents.filter((accident) => {
        const accidentDate = new Date(accident.date.seconds * 1000);
        return isWithinInterval(accidentDate, { start, end });
      }).length;
    };

    // Count accidents for current and previous months
    const currentMonthCount = countAccidentsInRange(
      currentMonth,
      endOfMonth(currentMonth)
    );
    const lastMonthCount = countAccidentsInRange(
      lastMonth,
      endOfMonth(lastMonth)
    );
    const twoMonthsAgoCount = countAccidentsInRange(
      twoMonthsAgo,
      endOfMonth(twoMonthsAgo)
    );

    // Calculate month-over-month change
    const monthOverMonthChange =
      lastMonthCount === 0
        ? 100
        : ((currentMonthCount - lastMonthCount) / lastMonthCount) * 100;

    // Calculate three-month trend
    const threeMonthTrend =
      twoMonthsAgoCount === 0
        ? "increasing"
        : currentMonthCount > twoMonthsAgoCount
        ? "increasing"
        : currentMonthCount < twoMonthsAgoCount
        ? "decreasing"
        : "stable";

    return {
      currentMonth: format(currentMonth, "MMMM"),
      currentMonthCount,
      lastMonthCount,
      monthOverMonthChange,
      threeMonthTrend,
      twoMonthsAgoCount,
    };
  }, [accidents]);

  // Accidents Over Time - updated to weekly
  const accidentsPerWeek = useMemo(() => {
    const weekCounts = {};

    accidents.forEach((accident) => {
      const date = new Date(accident.date.seconds * 1000);
      const weekStart = startOfWeek(date, { weekStartsOn: 1 }); // Assuming week starts on Monday
      const week = format(weekStart, "yyyy-'W'ww");

      if (!weekCounts[week]) {
        weekCounts[week] = 0;
      }
      weekCounts[week]++;
    });

    return Object.keys(weekCounts)
      .sort()
      .map((week) => ({
        week,
        count: weekCounts[week],
      }));
  }, [accidents]);

  // Accidents by Location
  const accidentsByLocation = useMemo(() => {
    const locationCounts = {};

    accidents.forEach((accident) => {
      const location = accident.location || "Unknown";

      if (!locationCounts[location]) {
        locationCounts[location] = 0;
      }
      locationCounts[location]++;
    });

    return Object.keys(locationCounts).map((location) => ({
      location,
      count: locationCounts[location],
    }));
  }, [accidents]);

  // Accidents by Fault Determination
  const accidentsByFault = useMemo(() => {
    const faultCounts = {};

    accidents.forEach((accident) => {
      // Normalize the fault value and ensure it's one of our three categories
      let fault = (accident.fault || "undetermined").toLowerCase();
      // Map any variations to our standard categories
      if (fault !== "our-fault" && fault !== "not-our-fault") {
        fault = "undetermined";
      }

      if (!faultCounts[fault]) {
        faultCounts[fault] = 0;
      }
      faultCounts[fault]++;
    });

    return Object.entries(faultCounts).map(([fault, count]) => ({
      name: fault,
      value: count
    }));
  }, [accidents]);

  // Update these colors for better contrast
  const FAULT_COLORS = {
    "our-fault": "#EF4444", // bright red
    "not-our-fault": "#10B981", // bright green
    "undetermined": "#6B7280", // gray
  };

  // Update the formatFaultLabel to be more concise
  const formatFaultLabel = (fault) => {
    switch (fault) {
      case "our-fault":
        return "Our";
      case "not-our-fault":
        return "Not Our";
      default:
        return "Undet.";
    }
  };

  if (isLoading) {
    return (
      <div className="space-y-6">
        {/* Trends Summary Loading State */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {[1, 2, 3].map((i) => (
            <div key={i} className="bg-gray-700 p-4 rounded-lg shadow">
              <Skeleton 
                height={24} 
                width={150} 
                baseColor="#374151" 
                highlightColor="#4B5563"
              />
              <div className="mt-2">
                <Skeleton 
                  height={36} 
                  width={80} 
                  baseColor="#374151" 
                  highlightColor="#4B5563"
                />
              </div>
              <div className="mt-1">
                <Skeleton 
                  height={16} 
                  width={120} 
                  baseColor="#374151" 
                  highlightColor="#4B5563"
                />
              </div>
            </div>
          ))}
        </div>

        {/* Charts Loading State */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[1, 2, 3].map((i) => (
            <div key={i} className="bg-gray-700 p-4 rounded-lg shadow">
              <Skeleton 
                height={24} 
                width={200} 
                className="mb-4"
                baseColor="#374151" 
                highlightColor="#4B5563"
              />
              <Skeleton 
                height={300} 
                baseColor="#374151" 
                highlightColor="#4B5563"
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Trends Summary */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-gray-700 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-white mb-2">
            Current Month
          </h3>
          <div className="text-3xl font-bold text-white">
            {trends.currentMonthCount}
          </div>
          <div className="text-sm text-gray-300">
            accidents in {trends.currentMonth}
          </div>
        </div>

        <div className="bg-gray-700 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-white mb-2">
            Monthly Change
          </h3>
          <div
            className={`text-3xl font-bold ${
              trends.monthOverMonthChange >= 0
                ? "text-red-400"
                : "text-green-400"
            }`}
          >
            {trends.monthOverMonthChange > 0 ? "+" : ""}
            {trends.monthOverMonthChange.toFixed(1)}%
          </div>
          <div className="text-sm text-gray-300">
            vs last month ({trends.lastMonthCount} accidents)
          </div>
        </div>

        <div className="bg-gray-700 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-white mb-2">
            3-Month Trend
          </h3>
          <div
            className={`text-3xl font-bold ${
              trends.threeMonthTrend === "increasing"
                ? "text-red-400"
                : trends.threeMonthTrend === "decreasing"
                ? "text-green-400"
                : "text-yellow-400"
            }`}
          >
            {trends.threeMonthTrend.charAt(0).toUpperCase() +
              trends.threeMonthTrend.slice(1)}
          </div>
          <div className="text-sm text-gray-300">based on 3-month pattern</div>
        </div>
      </div>

      {/* Charts grid - updated to 3 columns */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Existing Line Chart - updated to weekly */}
        <div className="bg-gray-700 p-4 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-2 text-white">
            Accidents Over Time (Weekly)
          </h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={accidentsPerWeek}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="week" stroke="#9CA3AF" />
              <YAxis stroke="#9CA3AF" />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#1F2937",
                  border: "none",
                  borderRadius: "0.5rem",
                }}
                labelStyle={{ color: "#9CA3AF" }}
              />
              <Line type="monotone" dataKey="count" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* Existing Bar Chart */}
        <div className="bg-gray-700 p-4 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-2 text-white">
            Accidents by Location
          </h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={accidentsByLocation}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="location" stroke="#9CA3AF" />
              <YAxis stroke="#9CA3AF" />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#1F2937",
                  border: "none",
                  borderRadius: "0.5rem",
                }}
                labelStyle={{ color: "#9CA3AF" }}
              />
              <Bar dataKey="count" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* New Pie Chart for Fault Distribution */}
        <div className="bg-gray-700 p-4 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-2 text-white">
            Fault Distribution
          </h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={accidentsByFault}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label={({ name, value, percent }) => (
                  `${formatFaultLabel(name)}: ${(percent * 100).toFixed(0)}%`
                )}
                labelLine={true}
                fill="#8884d8"
              >
                {accidentsByFault.map((entry) => (
                  <Cell
                    key={entry.name}
                    fill={FAULT_COLORS[entry.name] || "#6B7280"}
                  />
                ))}
              </Pie>
              <Tooltip
                contentStyle={{
                  backgroundColor: "#374151", // Darker background
                  border: "none",
                  borderRadius: "0.5rem",
                  padding: "8px 12px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
                }}
                itemStyle={{
                  color: "#ffffff", // White text
                  fontSize: "14px",
                  padding: "4px 0"
                }}
                labelStyle={{
                  color: "#ffffff", // White text
                  fontWeight: "bold",
                  marginBottom: "4px"
                }}
                formatter={(value, name) => [
                  `${value} accidents (${((value / accidents.length) * 100).toFixed(1)}%)`,
                  formatFaultLabel(name) + " Fault"
                ]}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default AccidentStats;
